<template>
	<div class="flex-start">
		<el-popover placement="right" trigger="manual" v-model="wxPayQRcode">
			<div class="wxqrcode_warp" >		
				<div class="icon_colse" @click="colseWxQrcode()"></div>
				<div class="wxqrcode_warp_left" v-loading="wxQrcoswloading">
					<div id="wxqrcode" :style="{width:'200px',height:'200px'}"></div>
					<div class="wxqrcode_bottom">
						<div class="sys_icon"></div>
						<div class="sys-desc">
							<div>请使用微信扫一扫</div>
							<div>扫描二维码支付</div>
						</div>
					</div>
				</div>
				<div class="wxqrcode_warp_right"></div>
			</div>	
			<div class="rest_btn" slot="reference" @click="showWXQRcode()">
				<div class="icon_weixin"></div>
				<div>微信支付</div>
			</div>
		</el-popover>
		<el-popover placement="right" width="400" trigger="manual" v-model="zfbPayQRcode">
			<div class="wxqrcode_warp" >		
				<div class="icon_colse" @click="colseAlipayQrcode()"></div>
				<div class="wxqrcode_warp_left" v-loading="alipayQrcoswloading">
					<div id="alipayqrcode" :style="{width:'200px',height:'200px'}"></div>
					<div class="wxqrcode_bottom">
						<div class="sys_icon"></div>
						<div class="sys-desc">
							<div>请使用支付宝扫一扫</div>
							<div>扫描二维码支付</div>
						</div>
					</div>
				</div>
				<div class="zfbqrcode_warp_right"></div>
			</div>	
			<div class="rest_btn" slot="reference" @click="showZFBQRcode()">
				<div class="icon_zfb"></div>
				<div>支付宝支付</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		commitFileUrl,queryUrlWithUInfo,postWithUInfo
	} from '@/api/index';
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
				wxPayQRcode:false,
				zfbPayQRcode:false,
				wxQrcoswloading:true,
				alipayQrcoswloading:true,
				checkInterval:null
			}
		},
		methods: {
			showWXQRcode(){
				this.$confirm('是否确定支付？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.requestWxQrcode()
				})
			},
			async requestWxQrcode(){
				this.$emit('requestWxQrcode',null)
			},
			createWxqrcode(code,outTradeNo) {
				var vm = this;
				document.getElementById("wxqrcode").innerHTML = "";
				this.wxqrcode = new QRCode('wxqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.wxQrcoswloading = false
				this.checkInterval = setInterval(function(){
					vm.checkPayOrNot(outTradeNo)
				},2000);
			},
			showZFBQRcode(){
				this.$confirm('是否确定下单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.createZfbNative();
				})
			},
			createAlipayqrcode(code,outTradeNo) {
				var vm = this;
				document.getElementById("alipayqrcode").innerHTML = "";
				this.alipayqrcode = new QRCode('alipayqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.alipayQrcoswloading = false
				this.checkInterval = setInterval(function(){
					vm.checkPayOrNot(outTradeNo)
				},2000);
			},
			async createZfbNative(){
				this.$emit('requestAlipayQrcode',null)
			},
			colseWxQrcode(){
				this.wxPayQRcode = false
			},
			colseAlipayQrcode(){
				this.zfbPayQRcode = false
			},
			async checkPayOrNot(outTradeNo){
				const initres = await postWithUInfo("/apis/checkPayOrNot",{outTradeNo:outTradeNo});
				if(initres.ec == '0'){
					this.wxPayQRcode = false
					this.zfbPayQRcode = false
					this.$util.msg('支付完成')
					clearInterval(this.checkInterval)
					this.$emit('payFinishCallback',null)
				}
			}
		},
		watch:{
			wxPayQRcode:function(newVal,oldVal){
				if(newVal == false){
					clearInterval(this.checkInterval)
				}
			},
			zfbPayQRcode:function(newVal,oldVal){
				if(newVal == false){
					clearInterval(this.checkInterval)
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.rest_btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		cursor: pointer;
	
		.icon_weixin {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/PAY-WEIXIN.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}
	
		.icon_zfb {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/zfb.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}
	}
	
	.rest_btn:hover {
		color: #c88854;
	}
	.wxqrcode_warp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		//padding: 20px 0px 20px 20px;
		position: relative;
		.icon_colse {
			width: 26px;
			height: 26px;
			background: url(../../assets/icon/close.png) center no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
		.wxqrcode_warp_left {
			display: flex;
			flex-direction: column;
			.wxqrcode_bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;
				.sys-desc {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					font-size: 14px;
					color: #1d1b1b;
				}
				.sys_icon {
					background: url(../../assets/icon/saoyisao.png) center no-repeat;
					background-size: 100%;
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
			}
		}
		.wxqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/pc_icon_phone-bg.png) center no-repeat;
			background-size: 80%;
		}
		.zfbqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/zfbScan.png) center no-repeat;
			background-size: 90%;
		}
	}
</style>
