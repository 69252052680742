<template>
	<div id="weixin" class="qrcode">
		<div class="login-warp">
			<div class="input_item">
				<div class="input_warp">
					<el-select v-model="reqType" placeholder="请选择广告投放区域" @change="changeReqType($event)">
						<el-option v-for="item in adReqTypes" :key="item.reqType" :label="item.reqTypeName"
							:value="item.reqType">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="input_item">
				<div class="input_warp">
					<el-select v-model="timeType" placeholder="请选择广告投放时长" @change="changeTimeType($event)">
						<el-option v-for="item in pickTimesList" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="input_item">
				<div class="input_warp">
					<el-input placeholder="广告费用" :disabled="true" v-model="amt"></el-input>
				</div>
			</div>
			<div class="input_item">
				<div class="input_warp">
					<el-input placeholder="请输入想要投放的广告描述" v-model="contents" type="textarea" maxlength="200" :rows="4">
					</el-input>
				</div>
			</div>
			<div class="input_item">
				<div class="input_warp">
					<el-upload action="123" class="upload-demo" :http-request="uploadFilePassPort" :limit="1">
						<el-button size="small" type="primary">点击上传广告图</el-button>
						<div slot="tip" class="el-upload__tip">建议尺寸388*120像素</div>
					</el-upload>
				</div>
			</div>
			<div class="input_item">
				<zhifu @requestWxQrcode="requestWxQrcode" @requestAlipayQrcode="requestAlipayQrcode" @payFinishCallback="payFinishCallback" ref="zhifu"></zhifu>
			</div>
			<!-- <div>
				<div class="btn" @click="dealEmailLogin">提交</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import uploadFile from '@/components/upload/uploadFile.vue'
	import zhifu from '@/components/zhifu/index.vue'
	import {
		queryUrl,
		doPost,
		commitFileUrl,queryUrlWithUInfo,postWithUInfo
	} from '@/api/index';
	export default {
		components: {
			uploadFile,zhifu
		},
		data() {
			return {
				tel: '',
				contents: '',
				adListInfo: [],
				adReqTypes: [],
				pickTimesList: [],
				reqType: '',
				timeType: '',
				amt: '',
				adfilePath: '',
				fileList:[],
				wxPayQRcode:false,
				zfbPayQRcode:false,
				wxQrcoswloading:true,
				alipayQrcoswloading:true,
				adId:''
			}
		},
		mounted() {
			this.getAdItemsList()
		},
		methods: {
			onSuccessFile(file, fileList){
				console.log(file, fileList);
			},
			payFinishCallback(){
				this.$store.dispatch('setAdvertiseDialogvisible', false)
			},
			checkValid(){
				if(this.reqType.length == 0){
					this.$message.error('请选择投放区域');
					return false;
				}
				if(this.timeType.length == 0){
					this.$message.error('请选择投放时长');
					return false;
				}
				if(this.contents.length == 0){
					this.$message.error('请输入广告描述');
					return false;
				}
				if(this.adfilePath.length == 0){
					this.$message.error('请上传广告图');
					return false;
				}
				return true
			},
			async requestWxQrcode(){
				if(this.checkValid()){
					this.$refs.zhifu.wxPayQRcode = true;
					if(this.adId.length > 0){
						this.pcAdWxCzOfH5QrCode(this.adId);
					}else{
						var params = {};
						params.reqType = this.reqType;
						params.pickTime = this.timeType;
						params.filePath = this.adfilePath;
						params.reqNail = this.contents
						params.launchType = '1';
						const initres = await queryUrlWithUInfo("/apis/launchAd",params);
						if(initres.ec == '0'){
							this.adId = initres.adid;
							this.pcAdWxCzOfH5QrCode(this.adId);
						}
					}
				}
			},
			async requestAlipayQrcode(){
				if(this.checkValid()){
					this.$refs.zhifu.zfbPayQRcode = true;
					if(this.adId.length > 0){
						this.pcAdAlipayCzOfH5QrCode(this.adId);
					}else{
						var params = {};
						params.reqType = this.reqType;
						params.pickTime = this.timeType;
						params.filePath = this.adfilePath;
						params.reqNail = this.contents;
						params.launchType = '1';
						const initres = await queryUrlWithUInfo("/apis/launchAd",params);
						if(initres.ec == '0'){
							this.adId = initres.adid;
							this.pcAdAlipayCzOfH5QrCode(this.adId);
						}
					}
				}
			},
			async pcAdWxCzOfH5QrCode(adId){
				var params = {};
				params.adid = adId;
				const initres = await postWithUInfo("/apis/pcAdWxCzOfH5QrCode",params);
				if(initres.ec == '0'){
					this.$refs.zhifu.createWxqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			async pcAdAlipayCzOfH5QrCode(adId){
				var params = {};
				params.adid = adId;
				const initres = await postWithUInfo("/apis/alipay/pcAdAlipayCzOfH5QrCode",params);
				if(initres.ec == '0'){
					this.$refs.zhifu.createAlipayqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			uploadFilePassPort(file) {
				// console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles', file.file);
				commitFileUrl("/apis/addImg", formData).then(res => {
					if (res.ec == '0') {
						this.adfilePath = res.path
					}
				});
			},
			changeReqType(event) {
				console.log(event)
				this.pickTimesList = []
				for (var index in this.adListInfo) {
					if (this.adListInfo[index].sdac_reqType == event) {
						this.pickTimesList.push({
							'key': this.adListInfo[index].sdac_ad_validateTime,
							'value': this.adListInfo[index].sdac_ad_validateName
						})
					}
				}
				this.computAmt()
			},
			changeTimeType(event) {
				this.timeType = event
				this.computAmt()
			},
			computAmt() {
				if (this.timeType.length > 0) {
					var chooseReqType = this.reqType
					var chooseValidate = this.timeType
					for (var index in this.adListInfo) {
						if (this.adListInfo[index].sdac_reqType == chooseReqType && this.adListInfo[index]
							.sdac_ad_validateTime == chooseValidate) {
							this.amt = this.adListInfo[index].sdac_cost
						}
					}
				} else {
					this.amt = 0
				}
			},
			async getAdItemsList() {
				var ress = await queryUrl("/apis/queryAdPickInfo", {});
				if (ress.ec == '0') {
					this.adListInfo = ress.adListInfo
					this.adReqTypes = ress.adReqTypes
				}
			},
			async dealEmailLogin() {
				let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
				if (!reg.test(this.tel)) {
					this.$message.error('请输入正确的手机号');
					return false;
				}
				if (this.contents.length == 0) {
					this.$message.error('请输入广告描述');
					return false;
				}
				var ress = await queryUrl("/apis/addAdverIntent", {
					tel: this.tel,
					contents: this.contents
				});
				var vm = this;
				if (ress.ec == '0') {
					this.contents = "";
					this.tel = "";
					vm.$store.dispatch('setAdvertiseDialogvisible', false)
					this.$util.msg("提交成功", {}, () => {

					});

				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.login-warp {
		.input_item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			position: relative;

			.item_head {
				width: 70px;
				text-align: right;
			}

			.input_warp {
				position: relative;
				width: 320px;
				text-align: left;
			}

			.yzm {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				/* background-color: #c88854; */
				color: #fff;
				line-height: 39px;
				padding: 0 10px;
				box-sizing: border-box;
				color: #222226;
				cursor: pointer;
			}

			.awaitSenconds {
				position: absolute;
				top: 10px;
				right: -70px;
				color: red;
			}
		}

		.btn {
			width: 100px;
			margin: 0 auto;
			background-color: #c88854;
			color: #fff;
			line-height: 36px;
			border-radius: 20px;
			margin-top: 50px;
			cursor: pointer;
		}

		.el-select {
			width: 100%;
		}

	}
</style>
