export default {
  basic: {
	name:"中东华人宝",
    home:'首页',
    flightcargo: '物流带货',
	freeride:'顺风车',
	markdownsale:'闲置市场',
	recruit:'工作机会',
	houseproperty:"房产仓库",
	locomotive:"车辆租售",
	delicacy:"本地餐厅",
	tourist:"本地旅游",
	interact:"国货出海",
	activity:"活动组织",
	discounts:"本地折扣",
	freeride:"顺风车",
	busoppo:"商业机会",
	tel:"本地通讯",
	rate:"华人互助",
	personCenter:"个人中心",
	login:"登录",
	register:"注册",
	workCenter:"工作台",
	messages:"我的消息",
	correcommend:"企业自荐",
	loadmore:"加载更多",
	eatdrink:"吃喝",
	play:"玩乐",
	life:"生活",
	roast:"吐槽",
	recommendLebels:[
		{'key': '0','value': '吃喝'},
		{'key': '1','value': '玩乐'},
		{'key': '2','value': '生活'},
		{'key': '3','value': '吐槽'},
		{'key': '4','value': '经验'}
	],
	reqList:[
		{'key':'1','value':'物流带货'},
		{'key':'2','value':'顺风车'},
		{'key':'3','value':'工作机会'},
		{'key':'4','value':'闲置市场'},
		{'key':'5','value':'活动组织'},
		/* {'key':'6','value':'拼团低价'}, */
		{'key':'7','value':'本地折扣'},
		{'key':'9','value':'车辆租售'},
		{'key':'10','value':'本地旅游'},
		{'key':'11','value':'房产仓库'},
		{'key':'12','value':'本地餐厅'},
		{'key':'13','value':'国货出海'},
		{'key':'14','value':'商业机会'},
		{'key':'15','value':'本地通讯'},
		{'key':'16','value':'华人互助'}
	],
	localContactsLabels : [
		{'key': '0','value': '酒店'},
		{'key': '1','value': '本地旅行社'},
		{'key': '2','value': '汽修公司'},
		{'key': '3','value': '餐厅'},
		{'key': '4','value': 'KTV&按摩、娱乐'},
		{'key': '5','value': '房产中介/房产经纪人'},
		{'key': '6','value': '车行'},
		{'key': '7','value': '手机、家电、电脑维修'},
		{'key': '8','value': '物流、仓储、快递'},
		{'key': '9','value': '翻译、教育'},
		{'key': '10','value': '理发店'},
		{'key': '11','value': '超市、商店'},
		{'key': '12','value': '鲜花、蛋糕店'},
		{'key': '13','value': '家政、搬家'},
		{'key': '14','value': '医院、诊所'},
		{'key': '15','value': '装修装潢'},
		{'key': '16','value': '银行服务、金融'},
		{'key': '17','value': '婚庆、摄影、视频服务'},
		{'key': '18','value': '律师、移民、保险'},
		{'key': '19','value': '会计事务所'},
		{'key': '20','value': '贸易'},
		{'key': '21','value': '代购'},
		{'key': '22','value': '商务咨询、商务中心'},
		{'key': '23','value': '广告、传媒'},
		{'key': '24','value': '理发店、美容美甲'},
		{'key': '25','value': '商会、公益机构'},
		{'key': '26','value': '全球领保热线'},
		{'key': '27','value': '本地使馆'},
		{'key': '28','value': '急救电话'},
		{'key': '29','value': '火警电话'},
		{'key': '00','value': '其他'}
	],
	inviteLabels : [
		{'key': '0','value': '工程'},
		{'key': '1','value': '财务、金融'},
		{'key': '2','value': '保洁、家政'},
		{'key': '3','value': '科技、电脑'},
		{'key': '4','value': '销售、公关、市场'},
		{'key': '5','value': '贸易'},
		{'key': '6','value': '文娱传媒、设计'},
		{'key': '7','value': '政府、公共事业'},
		{'key': '8','value': '司机'},
		{'key': '9','value': '教育、法律、翻译'},
		{'key': '10','value': '建筑'},
		{'key': '11','value': '仓储、物流'},
		{'key': '12','value': '人事、行政管理'},
		{'key': '13','value': '安保'},
		{'key': '14','value': '厨师、烹饪'},
		{'key': '15','value': '电商'},
		{'key': '16','value': '劳力'},
		{'key': '17','value': '娱乐休闲保健'},
		{'key': '18','value': '体育健身'},
		{'key': '19','value': '房产行业'},
		{'key': '20','value': '汽车行业'},
		{'key': '21','value': '高级管理'},
		{'key': '22','value': '旅游、会展'},
		{'key': '23','value': '餐饮、酒店'},
		{'key': '24','value': '文秘、助理'},
		{'key': '00','value': '其他'}
	],
	hireModel : [
		{'key': '0','value': '整租'},
		{'key': '1','value': '合租'}
	],
	carTransModel : [
		{'key':'0','value':'买车'},
		{'key':'1','value':'卖车'},
		{'key':'2','value':'车招租'},
		{'key':'3','value':'找车租'},
		{
			'key': 'nkcor4',
			'value': '汽车租赁公司'
		},
		{
			'key': 'nkcor5',
			'value': '汽修厂'
		},
		{
			'key': 'nkcor6',
			'value': '车行展厅'
		}
	],
	fangTransModel : [
		{
			'key': '0',
			'value': '我要买房'
		},
		{
			'key': '1',
			'value': '房屋出售'
		},
		{
			'key': '1-1',
			'value': '仓库出售'
		},
		{
			'key': '1-2',
			'value': '办公室出售'
		},
		{
			'key': '2',
			'value': '房屋招租'
		},
		{
			'key': '2-1',
			'value': '仓库招租'
		},
		{
			'key': '2-2',
			'value': '办公室招租'
		},
		{
			'key': '3',
			'value': '合租信息'
		},
		{
			'key': 'nkcor4',
			'value': '房产中介'
		},
		{
			'key': 'nkcor5',
			'value': '房产经纪人'
		}
	]
  }
}