export default {
  basic: {
	  name:"FAYANBO",
    home:'HOME',
    flightcargo: 'FLIGHT CARGO SHARING',
	freeride:'CARPOOL',
	markdownsale:'PRE-OWNED MARKET',
	recruit:'JOBS',
	houseproperty:"REAL ESTATE",
	locomotive:"MOTORS",
	delicacy:"LOCAL GOURMET",
	tourist:"TRAVEL MARKET",
	interact:"CHINA GOODS EXPORT",
	activity:"ACTIVITY & ORGNIZATION",
	discounts:"DISCOUNTS INFO SHARING",
	freeride:"CARPOOL",
	busoppo:"BUSINESS OPPORTUNITIES",
	tel:"LOCAL CONTACTS",
	rate:"CHINESE MUTUAL AID",
	personCenter:"个人中心",
	login:"LOGIN",
	register:"REGISTER",
	workCenter:"WORKBENCHES",
	messages:"MESSAGES",
	correcommend:"CORPORATE RECOMMENDATION",
	loadmore:"LOAD MORE",
	eatdrink:"DIET",
	play:"NAKE FUN",
	life:"LIFE",
	roast:"ROAST",
	recommendLebels : [
		{'key': '0','value': 'DIET'},
		{'key': '1','value': 'FUN'},
		{'key': '2','value': 'LIFE'},
		{'key': '3','value': 'ROAST'},
		{'key': '4','value': 'EXP'}
	],
	reqList:[
		{'key':'1','value':'FLIGHT CARGO SHARING'},
		{'key':'2','value':'CARPOOL'},
		{'key':'3','value':'JOBS'},
		{'key':'4','value':'PRE-OWNED MARKET'},
		{'key':'5','value':'ACTIVITY & ORGNIZATION'},
		/* {'key':'6','value':'拼团低价'}, */
		{'key':'7','value':'DISCOUNTS INFO SHARING'},
		{'key':'9','value':'MOTORS'},
		{'key':'10','value':'TRAVEL MARKET'},
		{'key':'11','value':'REAL ESTATE'},
		{'key':'12','value':'LOCAL GOURMET'},
		{'key':'13','value':'CHINA GOODS EXPORT'},
		{'key':'14','value':'BUSINESS OPPORTUNITIES'},
		{'key':'15','value':'LOCAL CONTACTS'},
		{'key':'16','value':'CHINESE MUTUAL AID'}
	],
	localContactsLabels : [
		{'key': '0','value': 'HOTEL'},
		{'key': '1','value': 'LOCAL TRAVEL AGENCY'},
		{'key': '2','value': 'AUTOMOBILE REPAIR COMPANY'},
		{'key': '3','value': 'RESTAURANT'},
		{'key': '4','value': 'KTV&MASSAGE、ENTERTAINMENT'},
		{'key': '5','value': 'ESTATE AGENCY'},
		{'key': '6','value': 'DEALERSHIP'},
		{'key': '7','value': 'PHONE、ELECTRICAL、COMPUTER REPAIR'},
		{'key': '8','value': 'LOGISTICS、STORAGE、 EXPRESS'},
		{'key': '9','value': 'TRANSLATE、EDUCATION'},
		{'key': '10','value': 'BARBERSHOP'},
		{'key': '11','value': 'SUPERMARKET、STORE'},
		{'key': '12','value': 'FLOWERS、CAKE SHOP'},
		{'key': '13','value': 'HOUSEKEEPING、MOVING HOUSE'},
		{'key': '14','value': 'HOSPITAL、CLINIC'},
		{'key': '15','value': 'INTERIOR DECORATION'},
		{'key': '16','value': 'BANK SERVICES、FINANCE'},
		{'key': '17','value': 'WEDDING、PHOTOGRAPHY、VIDEOSERVICES'},
		{'key': '18','value': 'LAWYER、IMMIGRANT、INSURANCE'},
		{'key': '19','value': 'ACCOUNTING FIRM'},
		{'key': '20','value': 'TRADE'},
		{'key': '21','value': 'HOTBOOM'},
		{'key': '22','value': 'BUSINESS CONSULTING/CENTER'},
		{'key': '23','value': 'ADVERTISEMENT、MEDIA'},
		{'key': '24','value': 'BARBERSHOP、BEAUTYMANICURE'},
		{'key': '25','value': 'COCERAL、PUBLIC INTEREST'},
		{'key': '26','value': '全球领保热线'},
		{'key': '27','value': '本地使馆'},
		{'key': '28','value': '急救电话'},
		{'key': '29','value': '火警电话'},
		{'key': '00','value': 'OTHER'}
	],
	inviteLabels : [
		{'key': '0','value': 'ENGINEERING'},
		{'key': '1','value': 'FINANCE'},
		{'key': '2','value': 'CLEANING、HOUSEKEEPING'},
		{'key': '3','value': 'TECHNOLOGY、COMPUTER'},
		{'key': '4','value': 'SALES、PUBLICIST、MARKET'},
		{'key': '5','value': 'TRADE'},
		{'key': '6','value': 'ENTERTAINMENT MEDIA、DESIGN'},
		{'key': '7','value': 'GOVERNMENT、PUBLIC UTILITIES'},
		{'key': '8','value': 'DRIVER'},
		{'key': '9','value': 'EDUCATION、LAW、TRANSLATE'},
		{'key': '10','value': 'ARCHITECTURE'},
		{'key': '11','value': 'STORAGE、LOGISTICS'},
		{'key': '12','value': 'PERSONNEL、ADMINISTRATIVE MANAGE'},
		{'key': '13','value': 'SECURITY'},
		{'key': '14','value': 'CHEF、COOKING'},
		{'key': '15','value': 'E-COMMERCE'},
		{'key': '16','value': 'LABOUR'},
		{'key': '17','value': 'RECREATION、RELAXATION、FITNESS'},
		{'key': '18','value': 'PHYSICAL FITNESS'},
		{'key': '19','value': 'ESTATE INDUSTRY'},
		{'key': '20','value': 'AUTO INDUSTRY'},
		{'key': '21','value': 'SENIOR MANAGE'},
		{'key': '22','value': 'TRAVEL、EXHIBITION'},
		{'key': '23','value': 'CATERING、HOTEL'},
		{'key': '24','value': 'SECRETARIAL、ASSISTANT'},
		{'key': '00','value': 'OTHER'}
	],
	hireModel : [
		{'key': '0','value': 'ENTIRE TENANCY'},
		{'key': '1','value': 'JOINT RENT'}
	],
	carTransModel : [
		{'key':'0','value':'买车'},
		{'key':'1','value':'卖车'},
		{'key':'2','value':'车招租'},
		{'key':'3','value':'找车租'},
		{
			'key': 'nkcor4',
			'value': '汽车租赁公司'
		},
		{
			'key': 'nkcor5',
			'value': '汽修厂'
		},
		{
			'key': 'nkcor6',
			'value': '车行展厅'
		}
	],
	fangTransModel : [
		{
			'key': '0',
			'value': '我要买房'
		},
		{
			'key': '1',
			'value': '房屋出售'
		},
		{
			'key': '1-1',
			'value': '仓库出售'
		},
		{
			'key': '1-2',
			'value': '办公室出售'
		},
		{
			'key': '2',
			'value': '房屋招租'
		},
		{
			'key': '2-1',
			'value': '仓库招租'
		},
		{
			'key': '2-2',
			'value': '办公室招租'
		},
		{
			'key': '3',
			'value': '合租信息'
		},
		{
			'key': 'nkcor4',
			'value': '房产中介'
		},
		{
			'key': 'nkcor5',
			'value': '房产经纪人'
		}
	]
  }
}
