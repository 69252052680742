<template>
	<div>
		<div>
			<div class="addCommentTit">
				<div class="avatarwarp">
					<img :src="showAvatarUrl(userInfo.avatarUrl)" @error="imgOnError" />
				</div>
				<div class="inputwarp">
					<div class="flex-start hundred">
						<div class="margr20" style="width: 240px;">
							<el-input placeholder="有需要就说出你的心里价位" maxlength="10" v-model="chujiaVal" clearable></el-input>
						</div>
						<div style="flex: 1;">
							<el-input :placeholder="lymsgPlaceHoder" v-model="chujiaMsg" maxlength="40" clearable></el-input>
						</div>
						
					</div>
					<div class="btn-warp" v-if="chujiaVal.length > 0">
						<div class="btn" @click="addMyPrice()">出价</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="topOfferList.length > 0 && hasSearch" class="cm-item-warp">
			<div v-for="(item,index) in topOfferList" :key="index">
				<div class="cm-item">
					<div class="flex-start-start  flexone">
						<div class="cm-it-head">
							<img :src="showAvatarUrl(item.sdu_avatarUrl)"></img>
						</div>
						<div class="flex-column-start cm-it-nick">
							<div class="flex-column-start">
								<div class="nick-name">{{item.sdu_nickName}}</div>
								<div class="cm-it-time">{{$util.fomatTime(item.sdro_addTime)}}</div>
							</div>
							<div v-if="item.sdro_msg && item.sdro_msg.length > 0" class="cjmsg">{{item.sdro_msg}}</div>
						</div>
					</div>
					<div class="flex-column-c-end item-price">
						<div class="flex-end">
							<div class="desc">出价</div>
							<div class="num">¥{{item.sdro_proPrice}}</div>
						</div>
						<div class="ll" @click="normalConTact(item)">聊一聊</div>
					</div>
				</div>
				<div class="line"></div>
			</div>
		</div>
		<div v-else-if="topOfferList.length == 0 && hasSearch" class="empty-warp">
			<el-empty description="暂无出价"></el-empty>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo
	} from '@/api/index';
	import { Message } from "element-ui";
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	export default {
		props: {
			reqNo: {
				type: String,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				topOfferList: [],
				hasSearch: false,
				lymsgPlaceHoder:'默默的出个价~',
				chujiaMsg:'',
				chujiaVal:'',
				defalutImg: require('@/assets/icon/noimg.jpg')
			}
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		created() {
			this.getPriceByReqNo()
		},
		mounted() {

		},
		methods: {
			async addMyPrice(){
				var _this = this;
				var msg = '';
				if(this.chujiaMsg.length > 0){
					msg = this.chujiaMsg;
				}else {
					msg = this.lymsgPlaceHoder;
				}
				var params = {};
				params.reqId = this.reqNo;
				params.inputPrice = this.chujiaVal;
				params.lymsg = this.msg;
				const initres = await queryUrlWithUInfo("/apis/addPriceForGood", params);
				if(initres.ec == '0'){
					this.$util.msg('出价成功');
					setTimeout(function() {
						_this.getPriceByReqNo();
					}, 1000);
					this.wxChujiaNotice(initres.cjId);
				}else{
					this.$util.warnMsg(initres.msg);
				}
				this.chujiaVal = '';
				this.chujiaMsg = '';
			},
			async wxChujiaNotice(cjId){
				var params = {};
				params.reqNo = this.reqNo;
				params.cjId = cjId;
				const initres = await queryUrlWithUInfo("/apis/wxChujiaNotice", params);
				if(initres.ec == '0'){
			
				}
			},
			async getPriceByReqNo() {
				var vm = this;
				queryUrl("/apis/getPriceByReqNo", {
					reqNo: vm.reqNo
				}).then(res => {
					vm.topOfferList = res.reList
					vm.hasSearch = true
				})
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}else{
					return ""
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.addCommentTit {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 10px;
		.avatarwarp {
			width: 50px;
			height: 50px;
			border-radius: 6px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.inputwarp {
			margin-left: 10px;
			line-height: 50px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			flex: 1;
		}
		.inputwarp .btn-warp {
			margin-top: 8px;
			display: flex;
			justify-content: flex-end;
		}
		
		.inputwarp .btn-warp .btn {
			width: 50px;
			height: 24px;
			border: 1px solid #c88854;
			color: #c88854;
			line-height: 24px;
			font-size: 12px;
			border-radius: 10px;
			cursor: pointer;
		}
	}
	.item-price {
		font-size: 14px;
	}

	.item-price .ll {
		font-size: 14px;
		cursor: pointer;
		margin-top: 10px;
		color: rgb(68, 68, 68);
	}

	.item-price .desc {
		font-size: 13px;
		margin-right: 4px;
	}

	.item-price {
		color: red;
	}

	.cm-item-warp {
		padding: 20px 10px 10px 10px;
	}

	.cm-item-warp .cm-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.cm-item-warp .cm-item .cm-it-nick {
		margin-left: 6px;
	}

	.nick-name {
		font-size: 14px;
		color: rgb(68, 68, 68);
	}

	.cm-item-warp .cm-item .cm-it-time {
		font-size: 12px;
		margin-top: 4px;
		color: #797676;
	}

	.cm-item .cm-it-head {
		width: 30px;
		height: 30px;
		border-radius: 20px;
		overflow: hidden;
	}

	.cm-item .cm-it-head img {
		width: 100%;
		height: 100%;
	}

	.line {
		border-bottom: 1px solid #ddd;
		height: 1px;
		width: 100%;
		margin: 10px 0;
	}

	.cm-item-warp .cm-item .cjmsg {
		font-size: 14px;
		text-align: left;
		color: rgb(68, 68, 68);
		margin-top: 6px;
	}
</style>
