import { render, staticRenderFns } from "./submitAdvertise.vue?vue&type=template&id=405d1db8&scoped=true&"
import script from "./submitAdvertise.vue?vue&type=script&lang=js&"
export * from "./submitAdvertise.vue?vue&type=script&lang=js&"
import style0 from "./submitAdvertise.vue?vue&type=style&index=0&id=405d1db8&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405d1db8",
  null
  
)

export default component.exports