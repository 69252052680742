<template>
	<div id="weixin" class="qrcode">
		<div class="login-warp">
			<!-- <div class="input_item">
				<div class="input_warp"><el-input placeholder="邮箱地址" v-model="tel"></el-input></div>
			</div>
			<div class="input_item">
				<div class="input_warp">
					<el-input placeholder="6位数字验证码" v-model="vercode"></el-input>
					<div class="yzm" @click="sendMail">{{sendMailFlg?'已发送':'发送验证码'}}</div>
					<div class="awaitSenconds" v-if="sendMailFlg">剩余{{secondsLeft}}秒</div>
				</div>
			</div>
			<div>
				<div class="btn" @click="dealEmailLogin">登录</div>
			</div> -->
			<div class="login_qr_warp" v-loading="loading">
				<img :src="basePath + qrCodeSrc" v-if="!showRefresh"/>
				<div class="app-fail" v-else>
					<span class="fail-mark">
						<div class="fresh_warp" @click="refreshQrcode()">
							<i class="icon_fresh"></i>
						</div>
					</span>
					<span class="app-title">二维码失效 点击重试</span>
				</div>
			</div>
			<div class="wechat-title" v-if="!showRefresh">
				打开微信扫一扫，快速登录/注册
			</div>
		</div>
	</div>
</template>

<script>
	import {queryUrl,doPost} from '@/api/index';
	export default {
		props:{
		  baseUrlpath:{
		    type:String,
		    required:true
		  }
		},
		data(){
			return {
				tel:'',
				vercode:'',
				sendMailFlg:false,
				secondsLeft:60,
				showRefresh:false,
				qrCodeSrc:'',
				loading:true,
				qrCodetimeOut:null,
				basePath: ''
			}
		},
		mounted() {
			this.basePath = this.baseUrlHW;
			this.qrcodeCreate()
		},
		beforeDestroy(){
			console.log('销毁咯wxlogin')
		},
		methods:{
			async dealEmailLogin(){
				const mailReg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
				if(!mailReg.test(this.tel)){
					this.$message.error('请输入正确的邮箱');
					return false;
				}
				if(this.vercode.length == 0){
					this.$message.error('请输入验证码');
					return false;
				}
				var ress = await queryUrl("/apis/eMailLogin",{emailaddress:this.tel,vercode:this.vercode});
				var userId = "";
				if(ress.ec == '0'){
					userId = ress.userId;
				}else{
					this.$message.error(ress.msg);
					return false;
				}
				var res = await queryUrl("/apis/getUserInfoByUserId", {
					userId: userId
				});
				// console.log(res)
				if (res.ec == '0') {
					this.$store.dispatch('setToken', {
						token: res.sdu_userId
					})
					var userInfo = {
						avatarUrl: res.avatarUrl,
						nickName: res.nickName,
						vipEndTime: res.sdu_vipEndTime,
						fayanboId: res.sdu_userId,
						openId: res.sdu_openId
					};
					this.$store.commit('setStateAttr', {
						key: 'userInfo',
						val: userInfo
					})
					localStorage.setItem('userInfo', JSON.stringify(userInfo))
					this.$store.dispatch('setLoginDialogvisible', false)
					// console.log(this)
				}
			},
			async sendMail(){
				var vm = this;
				if(this.sendMailFlg){
					return false;
				}
				const mailReg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
				if(!mailReg.test(this.tel)){
					this.$message.error('请输入正确的邮箱');
					return false;
				}
				this.sendMailFlg = true
				var res = await queryUrl("/apis/sendMail",{emailaddress:this.tel});
				if(res.ec == '1'){
					this.sendMailFlg = false
					this.$message.error(res.msg);
				}else{
					this.secondsLeft = 60
					setInterval(function(){
						vm.secondsLeft = vm.secondsLeft - 1
					},1000)
				}
			},
			async qrcodeCreate(){
				this.loading = true
				var res = await queryUrl("/apis/wxLoginService/wxLogin",{});
				var count = 120
				var vm = this;
				if(res.ec == '0'){
					var loginUUid = res.loginUUid;
					this.qrCodeSrc = res.qrpath;
					this.loading = false
					this.qrCodetimeOut = setInterval(function(){
						vm.chechQrcodeScanRes(loginUUid);
						count = count - 1;
						if(count == 0){
							vm.showRefresh = true
							window.clearInterval(vm.qrCodetimeOut)
						}
					},3000)
				}
			},
			clearQrcodeInterval(){
				console.log("清除interval")
				window.clearInterval(this.qrCodetimeOut)
			},
			async chechQrcodeScanRes(loginUUid){
				var res = await queryUrl("/apis/wxLoginService/checkQrScanResult",{loginUUid:loginUUid});
				if(res.ec == '0'){
					localStorage.setItem("accesstoken",res.token)
					window.location.reload()
				}
			},
			async refreshQrcode(){
				this.showRefresh = false
				this.qrcodeCreate()
			},
			async dealLogin(){
				// console.log('登录')
				if(this.tel.length == 0){
					this.$message.error('请输入手机号');
					return false;
				}
				if(this.vercode.length == 0){
					this.$message.error('请输入验证码');
					return false;
				}
				var openId = 'oajAN5jeUZuiE40g5ZRwr433fk5w';
				var res = await queryUrl("/apis/getUserInfoByOpenId",{openId:openId});
				// console.log(res)
				if(res.ec == '0'){
					this.$store.dispatch('setToken',{token:openId})
					var userInfo = {
							avatarUrl:res.avatarUrl,
							nickName:res.nickName,
							vipEndTime:res.sdu_vipEndTime,
							fayanboId:res.sdu_userId,
							openId:openId
						};
					this.$store.commit('setStateAttr',{
						key:'userInfo',
						val:userInfo
					})
					localStorage.setItem('userInfo',JSON.stringify(userInfo))
					this.$store.dispatch('setLoginDialogvisible',false)
					// console.log(this)
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.fresh_warp {
		width: 60px;
		height: 60px;
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		cursor: pointer;
		.icon_fresh {
			display: block;
			width: 100%;
			height: 100%;
			background: url(../assets/icon/refresh.png) center no-repeat;
			background-size: 100%;
		}
	}
	.login-warp {
		.input_item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			position: relative;
			.item_head {
				width: 70px;
				text-align: right;
			}
			.input_warp {
				position: relative;
				width: 320px;
			}
			.yzm {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				/* background-color: #c88854; */
				color: #fff;
				line-height: 39px;
				padding: 0 10px;
				box-sizing: border-box;
				color: #222226;
				cursor: pointer;
			}
			.awaitSenconds {
				position: absolute;
				top: 10px;
				right: -70px;
				color: red;
			}
		}
		.btn {
			width: 100px;
			margin: 0 auto;
			background-color: #c88854;
			color: #fff;
			line-height: 36px;
			border-radius: 20px;
			margin-top: 50px;
			cursor: pointer;
		}
		.login_qr_warp {
			width: 300px;
			height: 300px;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
			}
			.app-fail {
				position: relative;
				width: 100%;
				height: 100%;
				overflow: hidden;
				.fail-mark {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,.8);
					font-size: 14px;
					color: #fff;
					box-sizing: border-box;
					line-height: 1.5;
				}
				.app-title {
					position: absolute;
					display: block;
					width: 100%;
					bottom: 19px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					z-index: 10;
					cursor: pointer;
				}
			}

		}
	}
	.wechat-title {
		margin-top: 20px;
	}
</style>
