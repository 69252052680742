<template>
	<div class="pers-reqs-warp">
		<div class="reqType-warp">
			<div class="type-itm" :class="{'active':activeReqType == reqType.key}" v-for="(reqType,index) in reqTypeList" :key="reqType.key" @click="changeType(reqType.key)">{{reqType.value}}</div>
		</div>
		<div class="pers-req-body" v-loading="loading">
			<div v-for="(item,index) in reqList" :key="item.sdr_reqId" class="per-req-item">
				<reqInfo :item="item" :baseUrlpath="baseUrlpath"></reqInfo>
				<el-divider></el-divider>
			</div>
		</div>
		<div class="pub-btn-warp">
			<div class="flex-column-center">
				<div class="icon-add-empty" @click.stop="goToAddReq()"></div>
			</div>

		</div>
	</div>
</template>

<script>
	import {queryUrl,doPost} from '@/api/index';
	import { Toast } from 'mint-ui';
	import reqInfo from '../reqInfo.vue'
	export default {
		components:{
		  reqInfo
		},
		props:{
		  reqTypeList:{
		    type:Array,
		    required:true
		  },
		  baseUrlpath:{
		    type:String,
		    required:true
		  }
		},
		data(){
			return {
				reqList:[],
				hasSearch:false,
				currentPage1:'1',
				pageSize:'10',
				activeReqType:'all',
				loading:true
			}
		},
		created(){
			this.getPersonReqs()
		},
		mounted() {
		  
		},
		methods:{
			goToAddReq(){
				// console.log('go发布')
				this.$store.dispatch('setWorkcenterdrawer',false)
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			getPersonReqs(){
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				//params.sessionUserId = '1';
				params.reqType = this.activeReqType;
				queryUrl("/apis/getMyReqs",params).then(res=>{
				  vm.reqList = res.reqList
				  vm.loading = false
				})
			},
			changeType(type){
				if(this.activeReqType == type){
					return false;
				}
				this.activeReqType = type
				this.currentPage1 = '1';
				this.pageSize = '10';
				this.getPersonReqs()
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.pers-reqs-warp {
		padding: 10px;
		position: relative;
		height: calc(100% - 20px);
		overflow: hidden;
		.pub-btn-warp {
			position: absolute;
			bottom: 50px;
			right: 50px;
			background-color: #FFFFFF;
			border-radius: 40px;
			.icon-add-empty {
				background: url(../../assets/icon/add-cl.png) center no-repeat;
				background-size: 100%;
				width: 40px;
				height: 40px;
				cursor: pointer;
			}
		}
		.reqType-warp {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 10px;
			.type-itm {
				border: 1px solid #bfbfbf;
				color: #777474;
				padding: 2px 10px;
				border-radius: 6px;
				font-size: 10px;
				margin: 6px 6px 0 0;
				cursor: pointer;
			}
			.active {
				background-color: #C88854;
				color: #FFFFFF;
			}
		}
		.pers-req-body {
			padding: 20px 0px;
			height:calc(100% - 100px) ;
			overflow-y: scroll;
			display: flex;
			flex-wrap: wrap;
			.per-req-item {
				padding: 10px;
				width: calc((100% - 40px) / 2);
			}
		}
	}
</style>
