// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import MetaInfo from 'vue-meta-info'
import 'element-ui/lib/theme-chalk/index.css'
//import cookie from "js-cookie"
import Utils from './common/api'
import store from './store/index.js'
import './assets/css/common.css'
import i18n from './language'
//import webstore from './utils/webstore.js'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$util = Utils;
//Vue.prototype.$webstore = webstore;
Vue.prototype.baseUrl = '';
Vue.prototype.baseUrlCN = 'https://dxb.fayanbo.com/';
Vue.prototype.baseUrlHW = 'https://www.fayanbo.com/';
Vue.myloading = null;
Vue.use(i18n)
Vue.use(MetaInfo)
//Vue.prototype.baseUrl='http://127.0.0.1:9084';
//Vue.prototype.Cook = cookie

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: { App },
  template: '<App/>',
  mounted () {
      document.dispatchEvent(new Event('render-event'))
    }
})
