<template>
  <div class="pers-warp">
    <!-- <div class="pers-head">
      <div class="phi" @click="changeIndex('0')" :class="{'active':activeindex == '0'}">我的账号</div>
      <div class="phi" @click="changeIndex('2')" :class="{'active':activeindex == '2'}">我的发布</div>
      <div class="phi" @click="changeIndex('3')" :class="{'active':activeindex == '3'}">我的下单</div>
      <div class="phi" @click="changeIndex('4')" :class="{'active':activeindex == '4'}">我的收单</div>
    </div> -->
    <div class="pers-body" v-if="personInfo.nickName">
		<div v-if="activeindex == '0'" class="hund-h">
			<div class="pbi per-info">
				<div class="flex-start">
				  <div class="el-icon-user"></div>
				  <div class="pbi-tit">我的账号</div>
				</div>
				<div class="per-info-warp">
				  <div class="avatar">
					<img v-if="personInfo.avatarUrl" :src="showAvatarUrl(personInfo.avatarUrl)" @error="imgOnError"/>
					<img v-else :src="defalutImg" />
				  </div>
				  <div class="tagwarp">
					<div class="flex-start per-tag">
						<div>{{personInfo.nickName}}</div>
						<div class="flex-start smdb">
							<div class="sm" :class="[{'active':(personInfo.isRealname == '1')}]">
								<div class="icon-sm"></div>
								<div>{{personInfo.isRealname == '1'?'已实名':'未实名'}}</div>
							</div>
							<div class="db" :class="[{'active':(personInfo.isDb == '1')}]">
								<div class="icon-db"></div>
								<div>{{personInfo.isDb == '1'?'已担保':'未担保'}}</div>
							</div>
						</div>
					</div>
					<div class="flex-start per-tag" >
						<div class="tag">{{personInfo.work}}</div>
						<div class="tag" v-if="personInfo.area">
							<div class="area-name margr4">{{$util.getAreaNameByAc(personInfo.area)[0]}}</div>
							<div class="margr4">/</div>
							<div class="area-name">{{$util.getAreaNameByAc(personInfo.area)[1]}}</div>
						</div>
						<div class="tag" v-if="personInfo.isMarriage">{{$util.getDisPlay(personInfo.isMarriage,$util.marriageList)}}</div>
					</div>
				  </div>
				</div>
			</div>
			<div class="pbi">
				<div class="flex-around">
					<div class="pers-count">
					  <div class="num">{{personInfo.membercoins}}</div>
					  <div class="perc-label">我的金豆</div>
					</div>
					<div class="pers-count">
					  <div class="num">{{personInfo.attenTionNum}}</div>
					  <div class="perc-label">我的关注</div>
					</div>
					<div class="pers-count">
					  <div class="num">{{personInfo.fansNum}}</div>
					  <div class="perc-label">我的粉丝</div>
					</div>
				</div>
			</div>
			<div class="pbi per-info" @click="goToDetail('myreqs')">
				<div class="flex-start">
					<div class="el-icon-collection"></div>
					<div class="pbi-tit">我的发布</div>
				</div>
			</div>
			<div class="pbi per-info" @click="goToDetail('myPlaceOrders')">
				<div class="flex-start">
					<div class="el-icon-s-order"></div>
					<div class="pbi-tit">我的下单</div>
				</div>
			</div>
			<div class="pbi per-info" @click="goToDetail('myReceiptOrders')">
				<div class="flex-start">
					<div class="el-icon-s-order"></div>
					<div class="pbi-tit">我的收单</div>
				</div>
			</div>
			<!-- <div class="pbi per-info" @click="goToDetail('wallet')">
				<div class="flex-start">
					<div class="el-icon-coin"></div>
					<div class="pbi-tit">我的钱包</div>
				</div>
			</div>
			<div class="pbi per-info">
				<div class="flex-start">
					<div class="el-icon-user"></div>
					<div class="pbi-tit">实名认证</div>
				</div>
			</div>
			<div class="pbi per-info">
				<div class="flex-start">
					<div class="el-icon-connection"></div>
					<div class="pbi-tit">信用担保</div>
				</div>
			</div> -->
		</div>
		<div v-if="activeindex == '1'" class="hund-h">
			
		</div>
		<div v-if="activeindex == '2'" class="hund-h">
			<personReqs :baseUrlpath="baseUrlpath" :reqTypeList="reqTypeList"></personReqs>
		</div>
		<div v-if="activeindex == '3'" class="hund-h">
			<personXd :baseUrlpath="baseUrlpath" :reqTypeList="reqTypeList"></personXd>
		</div>
		<div v-if="activeindex == '4'" class="hund-h">
			<personSd :baseUrlpath="baseUrlpath" :reqTypeList="reqTypeList"></personSd>
		</div>
		<div class="bt_btn_logout">
			<el-popconfirm
			  title="确定要退出吗？"
			  @confirm="logOut"
			>
			  <div slot="reference">退出登录</div>
			</el-popconfirm>
		</div>
    </div>
  </div>
</template>

<script>
  import {queryUrl,doPost} from '@/api/index';
  import { Toast } from 'mint-ui';
  import personReqs from './person/personReqs.vue'
  import personSd from './person/personSd.vue'
  import personXd from './person/personXd.vue'
  export default {
	components:{
		personReqs,personSd,personXd
	},
    data() {
      return {
        userId:'1',
        personInfo:{
			avatarUrl:'',
			nickName:'',
			work:'',
			membercoins:'',
			area:'',
			isMarriage:'',
			isRealname:'',
			isDb:'',
			attenTionNum:'',
			fansNum:'',
			publishNum:''
		},
		activeindex:'0',
		reqTypeList:[],
		baseUrlpath:'',
		defalutImg:require('@/assets/icon/noimg.jpg')
      }
    },
    created(){
		// console.log("personCenter==>created")
		this.baseUrlpath = this.baseUrl;
		this.reqTypeList = JSON.parse(JSON.stringify(this.$util.reqList));
		this.reqTypeList.unshift({'key':'all','value':'全部'})
    },
    mounted() {
		// console.log("personCenter==>mounted")
      this.getPersonInfo();
    },
	computed:{
		
	},
    methods:{
		logOut(){
			// console.log("退出登录")
			localStorage.removeItem("userInfo")
			localStorage.removeItem('fayanboId')
			localStorage.removeItem("accesstoken")
			 window.location.reload();
		},
		changeIndex(index){
			this.activeindex = index
		},
		getPersonInfo(){
			var vm = this;
			queryUrl("/apis/getPersonInfo",{}).then(res=>{
				// console.log(res)
			  if(res.ec == '0'){
				vm.personInfo.avatarUrl = vm.showAvatarUrl(res.sdu_avatarUrl);
				vm.personInfo.nickName = res.sdu_nickName;
				vm.personInfo.work = res.sdu_work;
				vm.personInfo.area = res.sdu_areaId;
				vm.personInfo.isMarriage = res.sdu_isMarriage;
				vm.personInfo.isRealname = res.sdu_isRealname;
				vm.personInfo.isDb = res.sdu_isDb;
				vm.personInfo.attenTionNum = res.attenTionNum;
				vm.personInfo.fansNum = res.fansNum;
				vm.personInfo.publishNum = res.publishNum;
			  }
			});
			queryUrl("/apis/remainCostQuery",{}).then(res=>{
				// console.log(res)
			  if(res.ec == '0'){
				vm.personInfo.membercoins = res.membercoins;
			  }
			});
		},
		showAvatarUrl(avatarUrl){
			if(avatarUrl){
				if(avatarUrl.indexOf('http') >= 0){
					return avatarUrl;
				}else{
					return this.baseUrlpath + avatarUrl;
				}
			}
		},
		goToDetail(url){
			this.$store.dispatch('setWorkcenterdrawer',false)
			this.$router.push({
				name: url,
				query: {}
			});
		},
		imgOnError(e){
			const img = e.srcElement;
			img.src = this.defalutImg;
			img.onerror = null
		},
		showAvatarUrl(avatarUrl) {
			if (avatarUrl) {
				if (avatarUrl.indexOf('http') >= 0) {
					return avatarUrl;
				} else {
					return this.baseUrlpath + avatarUrl;
				}
			}
		}
    }
  }
</script>

<style lang="scss" scoped="scoped">
	.pers-warp {
		height: 100%;
		.pers-body {
			height: calc(100% - 0px);
			.hund-h {
				height: 100%;
			}
			.bt_btn_logout {
				position: absolute;
				bottom: 0;
				width: 100%;
				line-height: 50px;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}
  .pers-drawer {
	  .el-drawer__wrapper .pers-head {
	    display: flex;
	    line-height: 60px;
	    background-color: #c88854;
	    color: #FFFF;
	    font-weight: bold;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 999;
		justify-content: space-around;
		.active:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 40px;
			background-color: #FFFFFF;
			bottom: 6px;
			left: 50%;
			transform: translate(-50%, 0);
		}
	  }
	  .el-drawer__wrapper .pers-head .phi {
	    width: 100px;
		cursor: pointer;
		position: relative;
		
	  }
	  .el-drawer__wrapper .pers-body .pbi {
	    padding: 24px 20px;
	    border-bottom: 1px solid #e1e1e1;
	    line-height: 18px;
	    color: #181818;
	    letter-spacing: .16px;
	  }
	  .el-drawer__wrapper .pers-body .pbi .pbi-tit{
	    color: #181818;
	    font-size: 16px;
	    font-style: normal;
	    margin-left: 6px;
	  }
	  .el-drawer__wrapper .pers-body {
	    .per-info {
			cursor: pointer;
	      .per-info-warp {
			margin-top: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.tagwarp {
				height: 70px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;
				margin-left: 10px;
			}
			.per-tag {
				font-size: 14px;
				color: #181818;
				.tag {
					margin-right: 14px;
					display: flex;
					.area-name {
						font-size: 14px;
					}
				}
				.smdb {
					font-size: 12px;
					.sm {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						background-color: #8C939D;
						color: #FFFFFF;
						padding: 4px 6px;
						border-radius: 6px;
						margin-left: 10px;
						.icon-sm {
							background: url(../assets/icon/sm.png) center no-repeat;
							background-size: 100%;
							height: 14px;
							width: 14px;
							margin-right: 4px;
						}
					}
					.db {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						background-color: #8C939D;
						color: #FFFFFF;
						padding: 4px 6px;
						border-radius: 6px;
						margin-left: 10px;
						.icon-db {
						  background: url(../assets/icon/db.png) center no-repeat;
						  background-size: 100%;
						  height: 14px;
						  width: 14px;
						  margin-right: 4px;
						}
					}
				}
				.active {
					background-color: #c88854 !important;
				}
			}
	        .avatar {
	          width: 70px;
	          height: 70px;
	          border-radius: 22px;
	          img {
	            width: 100%;
	            height: 100%;
	          }
	        }
	      }
	    }
		.pers-count {
			height: 60px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			.num {
				
			}
			.perc-label {
				font-size: 14px;
				color: #181818;
				letter-spacing: .16px;
				font-weight: 500;
			}
		}
	  }
  }
	::-webkit-scrollbar {
		width: 0 !important;
	}
	::-webkit-scrollbar {
		width: 0 !important;height: 0;
	}
</style>
