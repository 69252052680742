import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		openExamine: false,//是否开启审核状态
		token: '',
		userInfo: {
			avatarUrl:'',
			nickName:'',
			vipEndTime:'',
			fayanboId:'',
			openId:''
		},
		timerIdent: false,//全局1s定时器，只在全局开启一个，所有需要定时执行的任务监听该值即可，无需额外开启
		cartCount: 0, //购物车数量
		orderCount: {}, //订单数量
		couponCount: 0, //可用优惠券数量
		// Socket连接状态
		IsOpen:false,
		// SocketTask
		SocketTask:false,
		// 是否上线
		IsOnline:false,
		chatMsgList:[],
		chatUnreadList:[],
		//定位所在地区
		areaIdrightNow:'156',
		longitude:'',
		latitude:'',
		unReadMsgCount:0,
		workcenterdrawer:false,
		loginDialogvisible:false,
		advertiseDialogvisible:false,
		menuIndexstr:'main'
	},
	getters: {
		userInfo(state){
			return state.userInfo
		},
		fayanboId(state){
			if(state.userInfo){
				return state.userInfo.fayanboId
			}else{
				return "";
			}
		},
		menuIndexstr(state){
			return state.menuIndexstr
		},
		workcenterdrawer(state){
			return state.workcenterdrawer
		},
		loginDialog(state){
			return state.loginDialogvisible
		},
		advertiseDialog(state){
			return state.advertiseDialogvisible
		},
		hasLogin(state){
			return !!state.token;
		},
		isDb(state){
			if(state.userInfo.isDb == '1'){
				return true;
			}else {
				return false;
			}
		},
		isRealname(state){
			if(state.userInfo.isRealname == '1'){
				return true;
			}else {
				return false;
			}
		},
		ismember(state){
			var vipDeadLine = state.userInfo.vipDeadLine;
			// console.log("vipDeadLine====>"+vipDeadLine)
			if(state.userInfo.ismember == '1' && (new Date(vipDeadLine) > new Date())){
				return true;
			}else {
				return false;
			}
		},
		isPerfectInfo(state){
			if(state.userInfo.isPerfectInfo == '1'){
				return true;
			}else {
				return false;
			}
		},
		isYuweiManager(state){
			var mls = state.userInfo.mls;
			var isyunwei = false
			for(var index in mls){
				if(mls[index] == '00001'){
					isyunwei = true
				}
			}
			return isyunwei
		}
	},
	mutations: {
		setAdvertiseDialogvisible(state, param){
			state.advertiseDialogvisible = param;
		},
		setWorkcenterdrawer(state, param){
			state.workcenterdrawer = param;
		},
		setLoginDialogvisible(state, param){
			state.loginDialogvisible = param;
		},
		setUnreadMsgCount(state, param){
			state.unReadMsgCount = param.msgCount;
		},
		setAreaIdrightNow(state, param){
			state.areaIdrightNow = param.nationCode;
		},
		longitudeAndLatitude(state, param){
			state.longitude = param.longitude;
			state.latitude = param.latitude;
		},
		initChatMsgList(state, param){
			state.chatMsgList = [];
			state.chatMsgList = param;
		},
		initChatUnreadMsgList(state, param){
			state.chatUnreadList = [];
			state.chatUnreadList = param;
		},
		addChatMsgToList(state, param){
			var hasKey = false;
			for(var index in state.chatMsgList){
				if(param.userId == state.chatMsgList[index].userId){
					state.chatMsgList[index].msgList.unshift(JSON.parse(param.msgInfo) );
					hasKey = true;
				}
			}
			if(!hasKey){
				var msgList = [];
				msgList.push(JSON.parse(param.msgInfo) );
				state.chatMsgList.push({'userId':param.userId,'msgList':msgList})
			}
			// console.log(state.chatMsgList)
		},
		updateChatUnreadMsgList(state, param){
			var hasKey = false;
			for(var index in state.chatUnreadList){
				if(state.chatUnreadList[index].userId == param.userId){
					state.chatUnreadList[index].msgCount = parseInt(state.chatUnreadList[index].msgCount) + 1;
					hasKey = true;
				}
			}
			if(!hasKey){
				state.chatUnreadList.push({'userId':param.userId,'msgCount':1})
			}
		},
		initChatUnreadMsg(state, param){
			for(var index in state.chatUnreadList){
				if(state.chatUnreadList[index].userId == param.userId){
					state.chatUnreadList[index].msgCount = 0;
				}
			}
		},
		addMoreChatMsg(state, param){
			for(var index in state.chatMsgList){
				if(state.chatMsgList[index].userId == param.userId){
					state.chatMsgList[index].msgList.push(...param.msgList);
				}
			}
		},
		//更新state数据
		setStateAttr(state, param){
			if(param instanceof Array){
				for(let item of param){
					state[item.key] = item.val;
				}
			}else{
				state[param.key] = param.val;
			}
		},
		setUserInfo(state, param){
			state.userInfo[param.key] = param.val;
		},
		//更新token
		setToken(state, data){
			state.token = data.token;
			localStorage.setItem('HRB_TOKEN',data.token)
			//uni.setStorageSync('ssid', data.token);
		},
		//退出登录
		logout(state){
			state.token = '';
			localStorage.clear();
			setTimeout(()=>{
				state.userInfo = {};
			}, 1100)
		},
		initModel(state){
			state.token = localStorage.getItem('HRB_TOKEN');
			if(localStorage.getItem('HRB_MENU_INDEX')){
				state.menuIndexstr  = localStorage.getItem('HRB_MENU_INDEX');
			}else{
				state.menuIndexstr  = 'main';
			}
			/* if(localStorage.getItem('userInfo')){
				state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			} */
		},
		setMenuIndexstr(state, param){
			state.menuIndexstr = param;
			// console.log('state.menuIndexstr====>',state.menuIndexstr)
			localStorage.setItem('HRB_MENU_INDEX',param);
		}
	},
	actions: {
		setMenuIndexstr(context,param){
			context.commit('setMenuIndexstr',param)
		},
		initModel({state, commit},param){
			commit('initModel',param)
		},
		setToken({state, commit},param){
			commit('setToken',param)
		},
		setUserInfo({state, commit},param){
			commit('setUserInfo',param)
		},
		async setWorkcenterdrawer({state, commit},param){
			commit('setWorkcenterdrawer',param)
		},
		async setLoginDialogvisible({state, commit},param){
			commit('setLoginDialogvisible',param)
		},
		async setAdvertiseDialogvisible({state, commit},param){
			commit('setAdvertiseDialogvisible',param)
		},
		//更新用户信息
		async getUserInfo({state, commit}){
			return state.userInfo;
		},
		async getUnReadMsgCount({state, commit}){
			return state.unReadMsgCount;
		},
		async changeUnReadMsgCount({state, commit},param){
			commit('setUnreadMsgCount',param)
		},
		openSocket({ state,dispatch,commit }){
			// 防止重复连接
			if(state.IsOpen) return
			// 连接
			state.SocketTask = uni.connectSocket({
				url: 'wss://www.fayanbo.com/websocket/'+state.userInfo.userId,
				//url: 'ws://localhost:8082/websocket/'+state.userInfo.userId,
				complete: ()=> {}
			});
			if (!state.SocketTask) return;
			// 监听开启
			state.SocketTask.onOpen(()=>{
				// 将连接状态设为已连接
				// console.log('将连接状态设为已连接');
				state.IsOpen = true
			})
			// 监听关闭
			state.SocketTask.onClose(()=>{
				// console.log('连接已关闭');
				state.IsOpen = false;
				state.SocketTask = false;
				state.IsOnline = false;
				dispatch("openSocket");
				// 清空会话列表
				// 更新未读数提示
			})
			 // 监听错误
			state.SocketTask.onError(()=>{
				// console.log('连接错误');
				state.IsOpen = false;
				state.SocketTask = false;
				state.IsOnline = false
			})
			// 监听接收信息
			state.SocketTask.onMessage((e)=>{
				// console.log('接收消息',e);
				if(e.data == 'ping'){
					return;
				}
				// 字符串转json
				let res = JSON.parse(e.data);
				commit('addChatMsgToList',{
					userId:res.fromUser,
					msgInfo: JSON.stringify(res) 
				});
				commit('updateChatUnreadMsgList',{
					userId:res.fromUser
				});
				// 处理接收信息
				if (res.type !== 'text') return;
			})
		}
	}
}) 


export default store
