<template>
	<div class="upload_warp">
		
		<template class="files_warp" v-if="tempList.length > 0">
			<template v-if="hasVideo">
				<div class="video_warp">
					<video :src="showAvatarUrl(tempList[0].path)" controls="controls"></video>
					<div class="del_icon" @click="del(tempList[0].fileId)"></div>
				</div>
			</template>
			<template v-else-if="!hasVideo && tempList.length > 0">
				<div class="file_warp" v-for="(item,index) in tempList" :key="item.fileId">
					<img width="100%" :src="showAvatarUrl(item.path)" alt="">
					<div class="del_icon" @click="del(item.fileId)"></div>
				</div>
			</template>
		</template>
		<el-upload action="123" :http-request="uploadFile" :multiple="multiple"
			:accept="acceptTypeDefalut" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccess"
			:on-remove="handleRemove" :show-file-list="false" :file-list="fileList">
			
			<template v-if="tempList.length < 9 && !hasVideo">
				<!-- <el-button size="small" type="primary">点击上传</el-button> -->
				<div class="el-icon-addself"></div>
			</template>
		</el-upload>
		<!-- <el-dialog :visible.sync="dialogVisible">
		  <video :src="dialogImageUrl" v-if="$util.isVideo(dialogImageUrl)"></video>
		  <img width="100%" :src="dialogImageUrl" alt="" v-else>
		</el-dialog> -->
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		commitFileUrl
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	export default {
		props: {
			fileList: {
				type: Array,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			},
			multiple: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				dialogImageUrl: '',
				dialogVisible: false,
				hasVideo: false,
				acceptTypeDefalut: 'image/*,video/*',
				imageAccept: 'image/*',
				videoAccept: 'video/*',
				tempList: [],
				dialogTableVisible:false
			}
		},
		watch: {
			tempList: {
				handler(nval, oval) {
					console.log(nval)
					if (nval.length > 0) {
						if (this.$util.isVideo(nval[0].path)) {
							this.hasVideo = true
						} else {
							this.hasVideo = false
						}
					}else{
						this.hasVideo = false
					}

				},
				deep: true,
				immediate: true
			}
		},
		mounted() {
			this.tempList = JSON.parse(JSON.stringify(this.fileList))
		},
		methods: {
			uploadFile(file, fileList) {
				this.$util.showMyLoading('上传中')
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles', file.file);
				commitFileUrl("/apis/addImg", formData).then(res => {
					if (res.ec == '0') {
						var params = {};
						params.file = {
							fileId: file.file.uid,
							path: res.path,
							url: res.path,
							thumbPath:res.coverpath
						};
						params.type = '1';
						this.$emit('addOrMulitiFileList', params)
						this.tempList.push({
							fileId: file.file.uid,
							path: res.path,
							url: res.path,
							thumbPath:res.coverpath
						})
					}
					vm.$util.hideMyLoading()
				});
			},
			handleRemove(file, fileList) {
				var params = {};
				params.file = {
					fileId: file.uid
				};
				params.type = '2';
				this.$emit('addOrMulitiFileList', params)
				this.tempList = this.tempList.filter((item, index) => {
					return item.fileId != file.uid
				});
			},
			del(fileId) {
				var params = {};
				params.file = {
					fileId: fileId
				};
				params.type = '2';
				this.$emit('addOrMulitiFileList', params)
				this.tempList = this.tempList.filter((item, index) => {
					return item.fileId != fileId
				});
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess(res, file) {
				// console.log(file)
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlHW + avatarUrl;
					}
				}
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.el-icon-addself {
		width: 80px;
		height: 80px;
		background: url('../../assets/icon/addImg.png') no-repeat center;
		background-size: 60%;
		background-color: #DCDFE6;
	}
	.upload_warp {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		.add_warp {
			width: 80px;
			height: 80px;
			line-height:80px;
			margin-right: 10px;
			border: 1px solid #DCDFE6;
		}
	}
	.video_warp {
		width: 180px;
		height: 80px;
		position: relative;
		margin-right: 14px;
		.del_icon {
			background: url(../../assets/icon/del_empty.png) no-repeat center;
			background-size: 60%;
			background-color: #F44336;
			width: 14px;
			height: 14px;
			border-radius: 40px;
			position: absolute;
			top: -7px;
			right: -7px;
			cursor: pointer;
		}
		video {
			width: 100%;
			height: 100%;
		}
	}
	.file_warp {
		width: 80px;
		height: 80px;
		position: relative;
		margin-right: 14px;
		.del_icon {
			background: url(../../assets/icon/del_empty.png) no-repeat center;
			background-size: 60%;
			background-color: #F44336;
			width: 14px;
			height: 14px;
			border-radius: 40px;
			position: absolute;
			top: -7px;
			right: -7px;
			cursor: pointer;
		}

		img {
			width: 100%;
			height: 100%;
		}

		
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item {
		width: 120px;
		height: 120px;
		line-height: 120px;
	}

	::v-deep .el-upload--picture-card {
		width: 120px;
		height: 120px;
		line-height: 120px;
	}
</style>
