<template>
	<div>
		<div class="item-warp">
			<div class="item-head flex-between">
				<div class="flex-start">
					<div class="avatar">
						<img :src="showAvatarUrl(item.buyerAvatarUrl)"></img>
					</div>
					<div class="nickname">{{item.buyerName}}</div>
				</div>
			</div>
			<div class="item-body flex-start">
				<div class="attach">
					<img :src="showAvatarUrl(item.attachPath)"></img>
				</div>
				<div class="bodynail">
					<div class="body_center">
						<div class="">
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '4'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{item.sdr_proName}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '1'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_carry_model,$util.carryModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '2'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_sfcar_model,$util.carModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '10'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_travel_tag,$util.travelLebels)}}</div>
							</div>
						</div>
						<div class="conts">{{$util.mySubStr($util.filterHtml(item.sdr_contents),200)}}</div>
					</div>
					<div class="bodyfoot">
						<div class="flex-end lb_warp">
							<div class="sttdesc" >{{$util.getDisPlay(item.srwp_payStt,$util.xdOrderTypeList)}}</div>
							<div class="flex-start">
								<div class="margr10">实收款</div>
								<div class="amt_warp">
									<div class="amticon">¥</div>
									<div class="amtnum">{{item.stof_amt}}</div>
								</div>
							</div>
						</div>
						<div class="item-foot flex-end">
							<div class="btn" @click.stop="orderDetail(item)">详情</div>
							<div class="btn" v-if="item.srwp_payStt == '1'" @click.stop="comfireDelivery(item)">确认收货</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrlWithUInfo
	} from '@/api/index';
	export default {
		name: 'orderSd',
		props: {
			item: {
				type: Object,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				defalutImage: require('../../assets/icon/noimg.jpg')
			}
		},
		created() {
			// console.log(this.item);
		},
		methods: {
			goto(url) {
				this.$router.push(url);
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			orderDetail(orderInfo){
				this.$router.push({
					name: 'orderDetail',
					query: {
						orderInfo: JSON.stringify(orderInfo) 
					}
				});
			},
			cashierOrder(orderInfo){
				this.$router.push({
					name: 'cashier',
					query: {
						orderInfo: JSON.stringify(orderInfo) 
					}
				});
			},
			comfireDelivery(){
				var _this = this;
				this.$confirm('是否确认发货', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.dealcomfireDelivery()
				}).catch(() => {
					     
				});
			},
			async dealcomfireDelivery(){
				var params = {};
				params.aOrderNo = this.item.srwp_payNo
				const initres = await queryUrlWithUInfo("/apis/comfireDelivery", params);
				if (initres.ec == '0') {
					this.$util.msg("确认发货成功");
					this.$emit('parentQuery')
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.item-warp {
		display: flex;

		.item-foot {
			margin-top: 20px;

			.btn {
				border: 1px solid #7b7777;
				padding: 0 12px;
				border-radius: 6px;
				font-size: 13px;
				line-height: 24px;
				cursor: pointer;
				margin: 0 6px;
			}
		}

		.item-head {
			margin-bottom: 10px;

			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickname {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}

		.item-body {
			height: 100px;
			flex: 1;
			margin-left: 20px;

			.attach {
				width: 100px;
				height: 100px;
				overflow: hidden;
				border-radius: 10px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.bodynail {
				height: 100%;
				flex: 1;
				display: flex;
				justify-content: space-between;

				.body_center {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-around;
					margin-left: 20px;
					flex: 1;
				}

				.fontsmc {
					color: #C88854;
					font-size: 14px;
					text-align: left;
				}

				.conts {
					text-align: left;
					width: 100%;
					font-size: 14px;
				}

				.bodyfoot {
					font-size: 14px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					width: 200px;
					.lb_warp {
						line-height: 28px;

						.amt_warp {
							display: flex;
							justify-content: center;
							align-items: baseline;
							color: #F44336;

							.amtnum {
								font-size: 16px;
								font-weight: bold;
								margin-left: 2px;
							}
						}
					}

					.sttdesc {
						color: #C88854;
						margin-right: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}
</style>
