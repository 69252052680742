<template>
	<div>
		<div>
			<div class="addCommentTit">
				<div class="avatarwarp">
					<img :src="showAvatarUrl(userInfo.avatarUrl)" @error="imgOnError" />
				</div>
				<div class="inputwarp">
					<el-input type="textarea" :rows="2" placeholder="请输入内容" maxlength="300" show-word-limit
						v-model="textarea1">
					</el-input>
					<div class="btn-warp" v-if="textarea1.length > 0">
						<div class="btn" @click="addComment()">发送</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="topCommentsList.length > 0" class="cm-item-warp">
			<div class="cm-item" v-for="(comment,index) in topCommentsList" :key="comment.sde_sid" @click="">
				<div class="cm-conts-warp">
					<div class="flex-between hundred">
						<div class="flex-start nick-warp">
							<div class="cm-it-head" @click.stop="gotoPerson(comment.sde_userId)">
								<img :src="showAvatarUrl(comment.sdu_avatarUrl)" @error="imgOnError"></img>
							</div>
							<div class="cm-it-nick">
								<div>{{comment.sdu_nickName}}</div>
							</div>
						</div>
						<div class="cm-it-time">{{$util.fomatTime(comment.sde_addTime)}}</div>
					</div>	
					<div style="width: 100%;">
						<div class="cm-it-body-warp">
							<div class="cm-it-body" @click="replyComment(comment)">
								<div class="cb-nail">{{comment.sde_contents}}</div>
							</div>
							<div class="inputwarp" v-show="comment.appendCommentFlg && (replyedId == comment.sde_sid)">
								<el-input type="textarea" autosize placeholder="请输入内容" maxlength="100" show-word-limit
									v-model="textarea2">
								</el-input>
								<div class="btn-warp" v-if="textarea2.length > 0">
									<div class="btn" @click="addComment()">发送</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style="width: 100%;">
					<div class="cb-reply-warp" v-if="comment.replyList.length > 0">
						<div v-for="(replyInfo,index) in comment.replyList" :key="replyInfo.ser_sid">
							<div class="replyItemwarp">
								<div class="replyItem" @click.stop="replyToReply(comment,replyInfo)">
									<div class="nickName" @click.stop="gotoPerson(replyInfo.ser_userId)">
										{{replyInfo.sdu_nickName}}</div>
									<div class="malr colon">:</div>
									<div>{{replyInfo.ser_contents}}</div>
								</div>
								<div class="inputwarp"
									v-show="replyInfo.appendCommentFlg && (replyedId == replyInfo.ser_sid)">
									<el-input type="textarea" autosize placeholder="请输入内容" maxlength="100"
										show-word-limit v-model="textarea3">
									</el-input>
									<div class="btn-warp" v-if="textarea3.length > 0">
										<div class="btn" @click="addComment()">发送</div>
									</div>
								</div>
							</div>
							<div v-for="(replyToReply,indexx) in replyInfo.replyToReplylist"
								:key="replyToReply.serr_sid">
								<div class="replyItemwarp">
									<div class="replyItem" @click="replyToRReply(comment,replyInfo,replyToReply)">
										<div class="nickName" @click.stop="gotoPerson(replyToReply.serr_userId)">
											{{replyToReply.sdu_nickName}}</div>
										<div class="malr">回复</div>
										<div>{{replyToReply.sdu_nickName2}}</div>
										<div class="malr colon">:</div>
										<div>{{replyToReply.serr_contents}}</div>
									</div>
									<div class="inputwarp"
										v-show="replyToReply.appendCommentFlg && (replyedId == replyToReply.serr_sid)">
										<el-input type="textarea" autosize placeholder="请输入内容" maxlength="100"
											show-word-limit v-model="textarea4">
										</el-input>
										<div class="btn-warp" v-if="textarea4.length > 0">
											<div class="btn" @click="addComment()">发送</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
			</div>

		</div>
		<div v-else-if="topCommentsList.length == 0 && hassearch" class="empty-warp">
			<el-empty description="暂无留言"></el-empty>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo
	} from '@/api/index';
	import { Message } from "element-ui";
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	export default {
		props: {
			reqNo: {
				type: String,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				topCommentsList: [],
				hassearch: false,
				commentInputFouce: false,
				replyedId: '',
				replyToCommentId: '',
				replyToReplyId: '',
				replyToRReplyId: '',
				textarea1: '',
				textarea2: '',
				textarea3: '',
				textarea4: '',
				defalutImg: require('@/assets/icon/noimg.jpg')
			}
		},
		created() {
			this.getComments();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods: {
			onCommentsfocus() {
				this.commentInputFouce = true
			},
			onCommentsblur() {
				this.commentInputFouce = false
			},
			getComments() {
				var vm = this;
				queryUrl("/apis/getCommentsByReqNo", {
					reqId: vm.reqNo
				}).then(res => {
					vm.topCommentsList = res.reqList;
					this.hassearch = true;
				});
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}else{
					return ""
				}
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			async addComment() {
				// console.log(this.textarea1);
				if (this.textarea1.length <= 0) {
					Message({
						message: '请输入内容~',
						type: 'error'
					});
					return false;
				}
				var params = {
					reqId: this.reqNo,
					inputMsg: this.textarea1,
					replyToCommentId: this.replyToCommentId,
					replyToReplyId: this.replyToReplyId,
					replyToRReplyId: this.replyToRReplyId
				}
				var vm = this;
				queryUrlWithUInfo("/apis/addComment", params).then(res => {
					if (res.ec == '0') {
						Message({
							message: '发送成功~',
							type: 'success'
						});
						this.textarea1 = '';
						this.replyToCommentId = '';
						this.replyToReplyId = '';
						this.replyToRReplyId = '';
						setTimeout(function() {
							vm.getComments();
						}, 1000);
					}
				});
			},
			replyComment(item) {
				item.appendCommentFlg = !item.appendCommentFlg
				if (item.appendCommentFlg) {
					this.replyedId = item.sde_sid
					this.replyToCommentId = item.sde_sid
					this.textarea2 = ''
				}
			},
			replyToReply(com, replyToReply) {
				replyToReply.appendCommentFlg = !replyToReply.appendCommentFlg;
				if (replyToReply.appendCommentFlg) {
					this.replyToCommentId = com.sde_sid
					this.replyToReplyId = replyToReply.ser_sid
					this.replyedId = replyToReply.ser_sid
					this.textarea3 = ''
				}
			},
			replyToRReply(com, replyToReply, replyToRReply) {
				replyToRReply.appendCommentFlg = !replyToRReply.appendCommentFlg;
				if (replyToRReply.appendCommentFlg) {
					// console.log('dsadsa')
					this.replyToCommentId = com.sde_sid
					this.replyToReplyId = replyToReply.ser_sid
					this.replyedId = replyToRReply.serr_sid
					this.replyToRReplyId = replyToRReply.serr_sid;
					this.textarea4 = ''
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.addCommentTit {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 10px;
	}

	.addCommentTit .inputwarp {
		margin-left: 10px;
		flex: 1;
	}

	.inputwarp .btn-warp {
		margin-top: 8px;
		display: flex;
		justify-content: flex-end;
	}

	.inputwarp .btn-warp .btn {
		width: 50px;
		height: 24px;
		border: 1px solid #c88854;
		color: #c88854;
		line-height: 24px;
		font-size: 12px;
		border-radius: 10px;
		cursor: pointer;
	}

	.addCommentTit .avatarwarp {
		width: 50px;
		height: 50px;
		border-radius: 6px;
		overflow: hidden;
	}

	.addCommentTit .avatarwarp img {
		width: 100%;
		height: 100%;
	}

	.empty-warp {
		margin-top: 20px;
	}

	.empty-warp .empty-msg {
		font-size: 20px;
	}

	.cm-item-warp {
		padding: 20px 10px 10px 10px;
	}

	.cm-item .cm-conts-warp {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
	}

	.cm-item-warp .cm-item .line {
		border-bottom: 1px solid #ddd;
		height: 1px;
		width: 100%;
		margin: 10px 0;
	}

	.cm-item-warp .cm-item:last-child .line {
		border-bottom: none
	}

	.cm-item-warp .cm-item {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
	}

	.cm-item-warp .cm-item .cm-it-body-warp {
		flex: 1;
		margin-left: 36px;
	}

	.cm-item-warp .cm-item .cm-it-body-warp .inputwarp {
		margin-top: 4px;
	}

	.cm-item-warp .cm-item .cm-it-body {

		min-height: 36px;
		cursor: pointer;
	}

	.cm-item-warp .cm-item .cm-it-body .cb-nail {
		font-size: 14px;
		text-align: left;
		color: rgb(68, 68, 68);
	}

	.cm-item-warp .cm-item .cm-it-head {
		width: 30px;
		height: 30px;
		border-radius: 40px;
		overflow: hidden;
	}

	.cm-item-warp .cm-item .cm-it-head img {
		width: 100%;
		height: 100%;
	}

	.cm-item-warp .cm-item .cm-it-nick {
		font-size: 15px;
		font-weight: bold;
		text-decoration: none;
		color: rgb(68, 68, 68);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 6px;
		height: 36px;
		justify-content: space-around;
		line-height: 36px;
	}

	.cm-conts-warp .cm-it-time {
		font-size: 12px;
		color: rgb(153, 153, 153);
		text-align: left;
		line-height: 36px;
	}

	.cb-reply-warp {
		margin-left: 36px;
		margin-top: 10px;
	}

	.replyItemwarp {
		width: 100%;
	}

	.cb-reply-warp .replyItem {
		display: flex;
		justify-content: flex-start;
		font-size: 12px;
		margin: 4px 0;
		cursor: pointer;
		min-height: 20px;
	}

	.cb-reply-warp .replyItem .nickName {
		font-weight: bold;
		color: #747474;
	}

	.cb-reply-warp .replyItem .malr {
		margin: 0 4px;
	}

	.cb-reply-warp .replyItem .colon {
		font-weight: bold;
	}
</style>
