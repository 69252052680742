import Vue from 'vue'
import store from '../store/index.js'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import main from '@/views/main.vue'
import login from '@/views/login/index.vue'
import reqDetail from '@/views/req/reqDetail.vue'
import middleForWxLogin from '@/views/middleForWxLogin.vue'
import publishIndex from '@/views/publish/index.vue'

Vue.use(Router)

let router = new Router({
  mode:"history",
  routes: [
	//运维菜单开始
	{
	  path: '/txManager',
	  name: 'txManager',
	  component: () => import('../views/ywCenter/txManager.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/certification',
	  name: 'certification',
	  component: () => import('../views/ywCenter/certification.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/tips',
	  name: 'tips',
	  component: () => import('../views/ywCenter/tips.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/reviewcase',
	  name: 'reviewcase',
	  component: () => import('../views/ywCenter/reviewcase.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/proposals',
	  name: 'proposals',
	  component: () => import('../views/ywCenter/proposals.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/advertisement',
	  name: 'advertisement',
	  component: () => import('../views/ywCenter/advertisement.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	//运维菜单结束
    {
      path: '/',
      name: 'main',
      component: main,
	  meta: {
	  		isWorkCenter:false,isMenu:true,KeepAlive:true
	  }
    },
	{
	  path: '/middleForPay',
	  name: 'middleForPay',
	  component: () => import('../views/middleForPay.vue'),
	  meta: {
	  		isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/publishIndex',
	  name: 'publishIndex',
	  component: publishIndex,
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubsfc',
	  name: 'pubsfc',
	  component: () => import('../views/publish/pubsfc.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubactivity',
	  name: 'pubactivity',
	  component: () => import('../views/publish/pubactivity.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubbrand',
	  name: 'pubbrand',
	  component: () => import('../views/publish/pubbrand.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubDiscounts',
	  name: 'pubDiscounts',
	  component: () => import('../views/publish/pubDiscounts.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubCnyGoodExpt',
	  name: 'pubCnyGoodExpt',
	  component: () => import('../views/publish/pubCnyGoodExpt.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubRate',
	  name: 'pubRate',
	  component: () => import('../views/publish/pubRate.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubcommerce',
	  name: 'pubcommerce',
	  component: () => import('../views/publish/pubcommerce.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubhouse',
	  name: 'pubhouse',
	  component: () => import('../views/publish/pubhouse.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/publocomotive',
	  name: 'publocomotive',
	  component: () => import('../views/publish/publocomotive.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubrecruit',
	  name: 'pubrecruit',
	  component: () => import('../views/publish/pubrecruit.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubsale',
	  name: 'pubsale',
	  component: () => import('../views/publish/pubsale.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubtravel',
	  name: 'pubtravel',
	  component: () => import('../views/publish/pubtravel.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubDelicacy',
	  name: 'pubDelicacy',
	  component: () => import('../views/publish/pubDelicacy.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubRoast',
	  name: 'pubRoast',
	  component: () => import('../views/publish/pubRoast.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/pubTel',
	  name: 'pubTel',
	  component: () => import('../views/publish/pubTel.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifysfc',
	  name: '/modifysfc',
	  component: () => import('../views/modify/pubsfc.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyactivity',
	  name: '/modifyactivity',
	  component: () => import('../views/modify/pubactivity.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyDiscounts',
	  name: '/modifyDiscounts',
	  component: () => import('../views/modify/pubDiscounts.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifybrand',
	  name: '/modifybrand',
	  component: () => import('../views/modify/pubbrand.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifycommerce',
	  name: '/modifycommerce',
	  component: () => import('../views/modify/pubcommerce.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyhouse',
	  name: '/modifyhouse',
	  component: () => import('../views/modify/pubhouse.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifylocomotive',
	  name: '/modifylocomotive',
	  component: () => import('../views/modify/publocomotive.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyRate',
	  name: '/modifyRate',
	  component: () => import('../views/modify/pubRate.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyrecruit',
	  name: '/modifyrecruit',
	  component: () => import('../views/modify/pubrecruit.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifysale',
	  name: '/modifysale',
	  component: () => import('../views/modify/pubsale.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifytravel',
	  name: '/modifytravel',
	  component: () => import('../views/modify/pubtravel.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyDelicacy',
	  name: '/modifyDelicacy',
	  component: () => import('../views/modify/pubDelicacy.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyCnyGoodExpt',
	  name: '/modifyCnyGoodExpt',
	  component: () => import('../views/modify/pubCnyGoodExpt.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyRoast',
	  name: '/modifyRoast',
	  component: () => import('../views/modify/pubRoast.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/modifyTel',
	  name: '/modifyTel',
	  component: () => import('../views/modify/pubTel.vue'),
	  meta: {
			needLogin:false,isWorkCenter:true,isMenu:false
	  }
	},
    {
      path: '/reqDetail',
      name: 'reqDetail',
      component: reqDetail,
	  meta: {
		  needLogin:false,isMenu:false
	  }
    },
    {
      path: '/flightcargo',
      name: 'flightcargo',
      component: () => import('../views/req/flightcargo.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/freeride',
      name: 'freeride',
      component: () => import('../views/req/freeride.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/recruit',
      name: 'recruit',
      component: () => import('../views/req/recruit.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/markdownsale',
      name: 'markdownsale',
      component: () => import('../views/req/markdownsale.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/tourist',
      name: 'tourist',
      component: () => import('../views/req/tourist.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/houseproperty',
      name: 'houseproperty',
      component: () => import('../views/req/houseproperty.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/locomotive',
      name: 'locomotive',
      component: () => import('../views/req/locomotive.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/activity',
      name: 'activity',
      component: () => import('../views/req/activity.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/discounts',
      name: 'discounts',
      component: () => import('../views/req/bigbrand.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/delicacy',
      name: 'delicacy',
      component: () => import('../views/req/delicacy.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/interact',
      name: 'interact',
      component: () => import('../views/req/interact.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/roast',
      name: 'roast',
      component: () => import('../views/req/roast.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
    {
      path: '/tel',
      name: 'tel',
      component: () => import('../views/req/tel.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
    },
	{
	  path: '/rate',
	  name: 'rate',
	  component: () => import('../views/req/rate.vue'),
	  meta: {
	  		isMenu:true,
			KeepAlive:true
	  }
	},
	{
	  path: '/myreqs',
	  name: 'myreqs',
	  component: () => import('../views/my/reqs.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/myPlaceOrders',
	  name: 'myPlaceOrders',
	  component: () => import('../views/my/myPlaceOrders.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/myReceiptOrders',
	  name: 'myReceiptOrders',
	  component: () => import('../views/my/myReceiptOrders.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/wallet',
	  name: 'wallet',
	  component: () => import('../views/my/wallet.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/personCenter',
	  name: 'personCenter',
	  component: () => import('../views/my/personCenter.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/myAcquiring',
	  name: 'myAcquiring',
	  component: () => import('../views/my/acquiring.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/proposal',
	  name: 'proposal',
	  component: () => import('../views/proposal/proposal.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/orderDetail',
	  name: 'orderDetail',
	  component: () => import('../views/my/order/orderDetail.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/cashier',
	  name: 'cashier',
	  component: () => import('../views/my/order/cashier.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/message',
	  name: 'message',
	  component: () => import('../views/message/message.vue'),
	  meta: {
	  		  needLogin:false,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/alipay/success',
	  name: 'alipaySuccess',
	  component: () => import('../views/alipay/success.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/main/recommendList',
	  name: '/main/recommendList',
	  component: () => import('../views/recommend/recommendList.vue'),
	  meta: {
	  		  needLogin:false,isWorkCenter:false,isMenu:false,KeepAlive:true
	  }
	},
	{
	  path: '/my/recommendList',
	  name: '/my/recommendList',
	  component: () => import('../views/recommend/my/recommendList.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/main/storeRecommendList',
	  name: '/main/storeRecommendList',
	  component: () => import('../views/storeRecommend/storeRecommendList.vue'),
	  meta: {
	  		  needLogin:false,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/my/storeRecommendList',
	  name: '/my/storeRecommendList',
	  component: () => import('../views/storeRecommend/my/storeRecommendList.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/storeRecommend/detail',
	  name: '/storeRecommend/detail',
	  component: () => import('../views/storeRecommend/detail.vue'),
	  meta: {
	  		  needLogin:false,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/recommend/detail',
	  name: '/recommend/detail',
	  component: () => import('../views/recommend/detail.vue'),
	  meta: {
	  		  needLogin:false,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/recommend/publish',
	  name: '/recommend/publish',
	  component: () => import('../views/recommend/publish.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/recommend/modify',
	  name: '/recommend/modify',
	  component: () => import('../views/recommend/modify.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/storeRecommend/publish',
	  name: '/storeRecommend/publish',
	  component: () => import('../views/storeRecommend/publish.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/storeRecommend/modify',
	  name: '/storeRecommend/modify',
	  component: () => import('../views/storeRecommend/modify.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/my/launchAd',
	  name: '/my/launchAd',
	  component: () => import('../views/my/ad/launchAd.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/my/modifyAd',
	  name: '/my/modifyAd',
	  component: () => import('../views/my/ad/modifyAd.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:false
	  }
	},
	{
	  path: '/my/advertisements',
	  name: '/my/advertisements',
	  component: () => import('../views/my/ad/advertisements.vue'),
	  meta: {
	  		  needLogin:true,isWorkCenter:true,isMenu:true
	  }
	},
	{
	  path: '/req/search',
	  name: '/req/search',
	  component: () => import('../views/req/search.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:false,isMenu:false
	  }
	},
	{
	  path: '/help',
	  name: '/help',
	  component: () => import('../views/help/help.vue'),
	  meta: {
	  		needLogin:false,isWorkCenter:false,isMenu:false
	  }
	}
  ]
})

router.beforeEach((to,from,next) => {
	// console.log(to)
	// console.log(from)
	// console.log(localStorage.getItem('accesstoken'))
	var token = localStorage.getItem('accesstoken')
	if(to.meta.needLogin && !token){
		store.dispatch('setLoginDialogvisible',true)
	}else {
		next()
	}
	
})

export default router