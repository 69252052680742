<template>
	<div id="foldBreadChart" >

	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo} from '@/api/index';
	export default {
		data() {
			return {

			}
		},
		mounted() {
			var vm = this;
			this.$nextTick(() => {
				vm.init();
			})
		},
		methods:{
			async init(){
				const initres = await queryUrl('/apis/statisticsByReqType',{});
				if(initres.ec == '0'){
					var reList = initres.reList;
					var data = []
					for (var index in reList) {
						if(index < 9){
							data.push({value:reList[index].reqCount,name:reList[index].reqTypeName})
						}
					}
					let newPromise = new Promise((resolve) => {
						resolve();
					});
					newPromise.then(() => {
						var myChart = echarts.init(document.getElementById('foldBreadChart'));
						let option = {
							title: {
								text: '需求类型分布图',
								left: 'left',
								textStyle:{
									color:'#C88854'
								}
							},
							tooltip: {
								trigger: 'item',
								formatter: '{b} : {c}笔 ({d}%)'
							},
							legend: {
								show:true,
								top: 'bottom'
							},
							toolbox: {
								show: true,
								feature: {
									mark: {
										show: false
									},
									dataView: {
										show: false,
										readOnly: false
									},
									restore: {
										show: false
									},
									saveAsImage: {
										show: false
									}
								}
							},
							series: [{
								name: 'Nightingale Chart',
								type: 'pie',
								radius: [20, 180],
								center: ['50%', '50%'],
								roseType: 'area',
								itemStyle: {
									borderRadius: 8
								},
								label: {
									show: false
								},
								data: data
							}]
						};
						myChart.setOption(option)
						window.addEventListener("resize",() => {
							if(myChart){
								myChart.resize()
							}
						})
					} )
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	#foldBreadChart {
		width: 100%;
		height: 100%;
	}
</style>
