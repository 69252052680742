<template>
  <div class="pers-warp">
    <!-- <div class="pers-head">
      <div class="phi" @click="changeIndex('0')" :class="{'active':activeindex == '0'}">我的账号</div>
      <div class="phi" @click="changeIndex('2')" :class="{'active':activeindex == '2'}">我的发布</div>
      <div class="phi" @click="changeIndex('3')" :class="{'active':activeindex == '3'}">我的下单</div>
      <div class="phi" @click="changeIndex('4')" :class="{'active':activeindex == '4'}">我的收单</div>
    </div> -->
    <div class="pers-body" v-if="personInfo.nickName">
		<div v-if="activeindex == '0'" class="hund-h bd_warp">
			<div class="pbi per-info">
				<!-- <div class="per-info-warp">
				  <div class="avatar">
					<img v-if="personInfo.avatarUrl" :src="showAvatarUrl(personInfo.avatarUrl)" @error="imgOnError"/>
					<img v-else :src="defalutImg" />
				  </div>
				  <div class="tagwarp">
					<div class="flex-start per-tag">
						<div class="nick-name">{{personInfo.nickName}}</div>
						<div :class="personInfo.sex == '0'?'man':'woman'"></div>
						<div class="flex-start smdb">
							<div class="sm" :class="[{'active':(personInfo.isRealname == '1')}]">
								<div class="icon-sm"></div>
								<div>{{personInfo.isRealname == '1'?'已实名':'未实名'}}</div>
							</div>
							<div class="db" :class="[{'active':(personInfo.isDb == '1')}]">
								<div class="icon-db"></div>
								<div>{{personInfo.isDb == '1'?'已担保':'未担保'}}</div>
							</div>
						</div>
					</div>
					<div class="flex-start per-tag" >
						<div class="tag">{{personInfo.work}}</div>
						<div class="tag" v-if="personInfo.area">
							<div class="area-name margr4">{{$util.getAreaNameByAc(personInfo.area)[0]}}</div>
							<div class="margr4">/</div>
							<div class="area-name">{{$util.getAreaNameByAc(personInfo.area)[1]}}</div>
						</div>
						<div class="tag" v-if="personInfo.isMarriage">{{$util.getDisPlay(personInfo.isMarriage,$util.marriageList)}}</div>
					</div>
				  </div>
				</div> -->
				<personInfo :personId="personId"></personInfo>
			</div>
			<div class="pbi">
				<div class="flex-around">
					<div class="pers-count">
					  <div class="num">{{personInfo.publishNum}}</div>
					  <div class="perc-label">发布</div>
					</div>
					<div class="pers-count">
					  <div class="num">{{personInfo.fansNum}}</div>
					  <div class="perc-label">关注</div>
					</div>
					<div class="pers-count">
					  <div class="num">{{personInfo.attenTionNum}}</div>
					  <div class="perc-label">粉丝</div>
					</div>
				</div>
			</div>
			<div class="req_pbi">
				<div class="req-warp">
					<div class="req-item" v-for="(item,index) in reqList" :key="item.sdr_reqId">
						<reqInfo :baseUrlpath="baseUrlpath" :item="item"></reqInfo>
					</div>
				</div>
			</div>
		</div>

    </div>
  </div>
</template>

<script>
  import {queryUrl,doPost,queryUrlWithUInfo} from '@/api/index';
  import reqInfo from '@/components/reqInfo.vue'
  import personInfo from '@/components/personInfo.vue'
  export default {
	components:{
		reqInfo,personInfo
	},
    data() {
      return {
        userId:'1',
        personInfo:{
			avatarUrl:'',
			nickName:'',
			work:'',
			membercoins:'',
			area:'',
			isMarriage:'',
			isRealname:'',
			isDb:'',
			attenTionNum:'',
			fansNum:'',
			publishNum:'',
			sex:''
		},
		activeindex:'0',
		reqTypeList:[],
		baseUrlpath:'',
		defalutImg:require('@/assets/icon/noimg.jpg'),
		pageSize:10,
		currentPage:1,
		reqList:[]
      }
    },
	props: {
		personId: {
			type: String,
			required: true
		}
	},
    created(){
		// console.log("personCenter==>created")
		this.baseUrlpath = this.baseUrl;
		this.reqTypeList = JSON.parse(JSON.stringify(this.$util.reqList));
		this.reqTypeList.unshift({'key':'all','value':'全部'})
    },
    mounted() {
		// console.log("personCenter==>mounted")
      this.getPersonInfo(this.personId);
	  this.getReqsByUserId(this.personId);
    },
	computed:{
		
	},
    methods:{
		logOut(){
			// console.log("退出登录")
			localStorage.removeItem("userInfo")
			localStorage.removeItem("HRB_TOKEN")
			localStorage.removeItem("accesstoken")
			 window.location.reload();
		},
		changeIndex(index){
			this.activeindex = index
		},
		async getReqsByUserId(personId){
			var params = {};
			params.queryUserId = personId;
			params.currentPage = this.currentPage;
			params.pageSize = this.pageSize;
			const initres = await queryUrlWithUInfo('/apis/getReqsByUserId',params);
			if(initres.ec == '0'){
				this.reqList = initres.reqList;
			}
		},
		async getPersonInfo(personId){
			var vm = this;
			var params = {};
			params.queryUserId = personId;
			const initres = await queryUrlWithUInfo('/apis/getPersonInfoByUserId',params);
			if(initres.ec == '0'){
				vm.personInfo.avatarUrl = vm.showAvatarUrl(initres.sdu_avatarUrl);
				vm.personInfo.nickName = initres.sdu_nickName;
				vm.personInfo.work = initres.sdu_work;
				vm.personInfo.area = initres.sdu_areaId;
				vm.personInfo.isMarriage = initres.sdu_isMarriage;
				vm.personInfo.isRealname = initres.sdu_isRealname;
				vm.personInfo.isDb = initres.sdu_isDb;
				vm.personInfo.attenTionNum = initres.attenTionNum;
				vm.personInfo.fansNum = initres.fansNum;
				vm.personInfo.publishNum = initres.publishNum;
				vm.personInfo.sex = initres.sdu_sex;
			}
		},
		showAvatarUrl(avatarUrl){
			if(avatarUrl){
				if(avatarUrl.indexOf('http') >= 0){
					return avatarUrl;
				}else{
					return this.baseUrlpath + avatarUrl;
				}
			}
		},
		goToDetail(url){
			this.$store.dispatch('setWorkcenterdrawer',false)
			this.$router.push({
				name: url,
				query: {}
			});
		},
		imgOnError(e){
			const img = e.srcElement;
			img.src = this.defalutImg;
			img.onerror = null
		},
		showAvatarUrl(avatarUrl) {
			if (avatarUrl) {
				if (avatarUrl.indexOf('http') >= 0) {
					return avatarUrl;
				} else {
					return this.baseUrlpath + avatarUrl;
				}
			}
		}
    }
  }
</script>

<style lang="scss" scoped="scoped">
	.req_pbi {
		padding: 20px 14px;
		flex: 1;
		overflow: scroll;
		.req-warp {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			.req-item {
				width: calc((100% - 24px) / 2);
				border-radius: 8px;
				box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
				overflow: auto;
				margin-bottom: 14px;
				margin-right: 12px;
			}
		}
	}
	.pers-warp {
		height: 100%;
		.pers-body {
			height: calc(100% - 0px);
			.bd_warp {
				display: flex;
				flex-direction: column;
			}
			.hund-h {
				height: 100%;
			}
			.bt_btn_logout {
				position: absolute;
				bottom: 0;
				width: 100%;
				line-height: 50px;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}
  .pers-drawer {
	  .el-drawer__wrapper .pers-head {
	    display: flex;
	    line-height: 60px;
	    background-color: #c88854;
	    color: #FFFF;
	    font-weight: bold;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 999;
		justify-content: space-around;
		.active:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 40px;
			background-color: #FFFFFF;
			bottom: 6px;
			left: 50%;
			transform: translate(-50%, 0);
		}
	  }
	  .el-drawer__wrapper .pers-head .phi {
	    width: 100px;
		cursor: pointer;
		position: relative;
		
	  }
	  .el-drawer__wrapper .pers-body .pbi {
	    padding: 14px 20px;
	    border-bottom: 1px solid #e1e1e1;
	    line-height: 18px;
	    color: #181818;
	    letter-spacing: .16px;
	  }
	  .el-drawer__wrapper .pers-body .pbi .pbi-tit{
	    color: #181818;
	    font-size: 16px;
	    font-style: normal;
	    margin-left: 6px;
	  }
	  .el-drawer__wrapper .pers-body {
		.pers-count {
			height: 60px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			.num {
				
			}
			.perc-label {
				font-size: 14px;
				color: #181818;
				letter-spacing: .16px;
				font-weight: 500;
			}
		}
	  }
  }
	::-webkit-scrollbar {
		width: 0 !important;
	}
	::-webkit-scrollbar {
		width: 0 !important;height: 0;
	}
</style>
