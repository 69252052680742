<template>
	<div class="pers-reqs-warp">
		<div class="reqType-warp">
			<div class="type-itm" :class="{'active':activeReqType == reqType.key}" v-for="(reqType,index) in reqTypeList" :key="reqType.key" @click="changeType(reqType.key)">{{reqType.value}}</div>
		</div>
		<div class="pers-req-body" v-loading="loading">
			<div v-for="(item,index) in orderList" :key="item.sdr_reqId">
				<orderSd :item="item" :baseUrlpath="baseUrlpath"></orderSd>
				<el-divider></el-divider>
			</div>
		</div>
	</div>
</template>

<script>
	import {queryUrl,doPost} from '@/api/index';
	import { Toast } from 'mint-ui';
	import orderSd from '../order/orderSd.vue'
	export default {
		components:{
		  orderSd
		},
		props:{
		  reqTypeList:{
		    type:Array,
		    required:true
		  },
		  baseUrlpath:{
		    type:String,
		    required:true
		  }
		},
		data(){
			return {
				orderList:[],
				hasSearch:false,
				currentPage1:'1',
				pageSize:'10',
				activeReqType:'all',
				reqOrderType:'all',
				loading:true
			}
		},
		created(){
			this.getPersonReqs()
		},
		mounted() {
		  
		},
		methods:{
			getPersonReqs(){
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				//params.sessionUserId = '2';
				params.reqType = this.activeReqType;
				params.reqOrderType = this.reqOrderType;
				queryUrl("/apis/queryMyOrdersOfSd",params).then(res=>{
				  vm.orderList = res.orderList
				  vm.loading = false
				})
			},
			changeType(type){
				if(this.activeReqType == type){
					return false;
				}
				this.activeReqType = type
				this.currentPage1 = '1';
				this.pageSize = '10';
				this.getPersonReqs()
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.pers-reqs-warp {
		padding: 10px;
		position: relative;
		height: calc(100% - 20px);
		overflow: hidden;
		.reqType-warp {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 10px;
			.type-itm {
				border: 1px solid #bfbfbf;
				color: #777474;
				padding: 2px 10px;
				border-radius: 6px;
				font-size: 10px;
				margin: 6px 6px 0 0;
				cursor: pointer;
			}
			.active {
				background-color: #C88854;
				color: #FFFFFF;
			}
		}
		.pers-req-body {
			padding: 20px 10px;
			height:calc(100% - 100px) ;
			overflow-y: scroll;
		}
	}
</style>
