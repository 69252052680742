
import Http from '../../utils/http.js'
import store from '../../store/index.js'
import Vue from 'vue'
import router from '../../router/index.js'
import { Message } from "element-ui";
var loading
let vm = new Vue();
const Utils = {};

Utils.showLoading = function(){
	loading = Vue.prototype.$loading({
	    lock: true,
	    text: '加载中',
	    target: 'html',
	    background: 'rgba(0, 0, 0, 0.2)'
	})
}

Utils.hideLoading = function(){
	loading.close()
}

Utils.textFormat = function(val){
	if (val) {
		let newString = val.replace(/\n/g, '_@').replace(/\r/g, '_#');
		newString = newString.replace(/_#_@/g, '<br/>'); // IE7-8
		newString = newString.replace(/_@/g, '<br/>'); // IE9、FF、chrome
		newString = newString.replace(/\s/g, '&nbsp;'); // 空格处理
		return newString;
	}else{
		return ''
	}
}

Utils.filterHtml = function(val){
	if (val) {
		let newString = val.replace(/<br\/>/g,'').replace(/&nbsp;/g,'').replace(/_@/g,'').replace(/\r/g,'').replace(/<[^>]+>/g,'');
		return newString
	}else{
		return ''
	}
}

Utils.formateContent = function(val){
	if (val) {
		let newString = val.replace(/<br\/>/g,'\n').replace(/&nbsp;/g,'');
		return newString
	}else{
		return ''
	}
}

Utils.formateContentRevise = function(val){
	if (val) {
		let newString = val.replace(/\n/g,'<br/>').replace(/\s/g,'&nbsp;');
		return newString
	}else{
		return ''
	}
}

Utils.formateWhatsApp = function(ac,num){
	if(Utils.isNotEmpty(ac)){
		if(ac.indexOf('+') >= 0){
			ac = ac.replace(/\+/g,'')
		} 
	}else{
		ac = ''
	}
	if(Utils.isNotEmpty(num)){
		//去除开头的 0
		while(num.substring(0,1) == '0'){
			num = num.substring(1,num.length);
		}
		//去除 + 和 -
		num = num.replace(/\+/g,'').replace(/\-/g,'')
	}else {
		num = ''
	}
	return ac.concat(num)
}

Utils.parsejson = function(str){
	var res = ''
	try {
		res = JSON.parse(str)
	}catch(e){
		
	}
	return res
}

Utils.isNotEmpty = function(str){
	if(str == null || str.length == 0 || str.trim().length == 0 || str == 'null'){
		return false
	}else{
		return true
	}
}

Utils.storeReqMapping = [{
		'key': '0',
		'value': '房产仓库'
	},
	{
		'key': '1',
		'value': '车辆租售'
	},
	{
		'key': '2',
		'value': '本地旅游'
	},
	{
		'key': '3',
		'value': '本地旅游'
	},
	{
		'key': '4',
		'value': '物流带货'
	},
	{
		'key': '5',
		'value': '本地餐厅'
	},
	{
		'key': '6',
		'value': '车辆租售'
	},
	{
		'key': '7',
		'value': '工作机会'
	}
]

Utils.isMobile = function(){
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	return flag;
}

Utils.showMyLoading = function(txt){
	vm.myloading = vm.$loading({
	  lock: true,
	  text: txt,
	  spinner: 'el-icon-loading',
	  background: 'rgba(0, 0, 0, 0.7)'
	});
}

Utils.hideMyLoading = function(){
	vm.myloading.close()
}

Utils.getUrlParams = function() {
  let geturl = window.location.href;
  let getqyinfo = geturl.split('?')[1]
  let getqys = getqyinfo.split('&')
  let obj = {} //创建空对象，接收截取的参数
  for (let i = 0; i < getqys.length; i++) {
    console.log(i)
    let item = getqys[i].split('=')
    let key = item[0]
    let value = item[1]
    obj[key] = value
  }
  return obj

}

Utils.goto = function(url,params = {}){
	router.push({
		name: url,
		params: params
	})
}

Utils.checkLogin = function(){
	const fayanboId = store.state.userInfo.fayanboId;
	if(fayanboId == null || fayanboId.length == 0){
		store.dispatch('setLoginDialogvisible',true)
		Message({
			message: "请先登录~",
			type: 'warning'
		});
		throw new Error('请先登录');
	}
}


Utils.initLogin = function(){
		// console.log('initLogin');
		const token = uni.getStorageSync('ssid');
		// console.log('token==>'+token);
		if(!!token){
			Utils.request({
				url: vm.baseUrl + 'getUserInfoByOpenId',
				params: {openId:token},
				method:'GET'
			}).then(initres => {
				// console.log('根据openId获取的信息===>'+JSON.stringify(initres))
				if(!initres.sdu_userId || !initres.sdu_unionId){
					wx.login({
						provider: 'weixin',
						success: res=> {
							var code = res.code;
							uni.showModal({
								title: '温馨提示',
								content: '亲，授权微信登录后才能正常使用小程序功能',
								success(res) {
									if (res.confirm) {
										mpWxGetUserInfo(code);
									}else if(res.cancel){

									}
								}
							})
						}
					})
				}else if(initres.ec == '0'){
					var userInfo = {};
					userInfo.avatarUrl = initres.avatarUrl;
					userInfo.nickName = initres.nickName;
					userInfo.userId = initres.sdu_userId;
					userInfo.signInFlag = initres.signInFlag;
					userInfo.isDb = initres.sdu_isDb;
					userInfo.ismember = initres.sdu_ismember == '1'?true:false;
					userInfo.isBindPhoneNum = initres.bindPhoneNum == '1'?true:false;
					userInfo.vipDeadLine = initres.sdu_vipEndTime;
					userInfo.isRealname = initres.sdu_isRealname;
					userInfo.isPerfectInfo = initres.sdu_isPerfectInfo;
					userInfo.areaCodeArr = initres.sdu_areaId;
					store.commit('setStateAttr', {
						key: 'userInfo',
						val: userInfo
					})
					// console.log("设置全局userInfo=====>"+JSON.stringify(userInfo) )
					getChatMsgList();
					//getUnReadChatMsgList();
					store.dispatch('openSocket')
					if(token){
						store.commit('setToken', {
							token
						});
					}
				}
			})

		}else{
			wx.login({
				provider: 'weixin',
				success: res=> {
					var code = res.code;
					uni.showModal({
						title: '温馨提示',
						content: '亲，授权微信登录后才能正常使用小程序功能',
						success(res) {
							if (res.confirm) {
								mpWxGetUserInfo(code);
							}else if(res.cancel){

							}
						}
					})
				}
			})

		}
}

function mpWxGetUserInfo(code){
	/* uni.login({
		provider: 'weixin',
		success: function (loginRes) {
			// console.log('login===>'+JSON.stringify(loginRes));
			// 获取用户信息

		}
	}); */
	uni.getUserProfile({
		provider: 'weixin',
		desc: '用于完善会员资料',
		success: function (uinfores) {
			// console.log('getUserInfo====>'+ JSON.stringify(uinfores));
			var reqParams = {};
			reqParams.code = code;
			reqParams.encryptedData = uinfores.encryptedData;
			reqParams.iv = uinfores.iv;
			loginForWx(reqParams);
		}
	});
	/* wx.getUserInfo({
		desc: 'weixin',
		withCredentials:true,
		success: async function(infoRes) {
			// console.log("getUserInfo获取结果 = >"+JSON.stringify(infoRes))
			var reqParams = {};
			reqParams.code = code;
			reqParams.encryptedData = infoRes.encryptedData;
			reqParams.iv = infoRes.iv;
			loginForWx(reqParams);
		},
		complete(e) {
			// console.log(e)
		}
	}); */
}

async function loginForWx(reqParams){
	const initres = await Utils.request({
		url: vm.baseUrl + 'loginForWx',
		params: reqParams,
		method:'GET'
	})
	// console.log('loginForWx====>'+ JSON.stringify(initres) )
	store.commit('setToken', {token:initres.ssid});
	var userInfo = {};
	userInfo.avatarUrl = initres.avatarUrl;
	userInfo.nickName = initres.nickName;
	userInfo.userId = initres.sdu_userId;
	userInfo.signInFlag = initres.signInFlag;
	userInfo.isDb = initres.sdu_isDb;
	userInfo.ismember = initres.sdu_ismember;
	userInfo.isRealname = initres.sdu_isRealname;
	userInfo.isPerfectInfo = initres.sdu_isPerfectInfo;
	store.commit('setStateAttr', {
		key: 'userInfo',
		val: userInfo
	})
	getChatMsgList();
	//getUnReadChatMsgList();
}

async function getChatMsgList(){
	const initres = await Utils.request({
		url: vm.baseUrl + 'getChatMsgList',
		params: {},
		method:'GET'
	})
	if(initres.ec == '0'){
		store.commit('initChatMsgList',initres.msgList);
		store.commit('initChatUnreadMsgList',initres.unReadMsgList);
	}
}

async function getUnReadChatMsgList(){
	const initres = await Utils.request({
		url: vm.baseUrl + 'getUnReadChatMsgList',
		params: {},
		method:'GET'
	})
	if(initres.ec == '0'){
		store.commit('initChatUnreadMsgList',initres.msgList);
	}
}

Utils.doGet = function(obj) {
  return Http.get(obj)
}

Utils.doPost = function(obj) {
  return Http.post(obj)
}

Utils.setStorage = function(key,value){
	uni.setStorage({
	    key: key,
	    data: value,
	    success: function () {
	        // console.log('异步储存 => ' + key);
	    }
	});
}

Utils.request = function(opt){
	return new Promise((resolve, reject) => {
		// console.log('储存的用户信息====>'+ JSON.stringify(store.state.userInfo) )
		if(!!store.state.userInfo.userId){
			opt.params.sessionUserId = store.state.userInfo.userId;
		}
		const _config = {
			data:opt.params,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
			method: opt.method,
			withCredentials: true,
		}
		opt.success = function(res){
			resolve(res.data);
		}
		opt.fail = function(res){
			uni.showToast({
				title:'网络错误，请检查网络',
				icon:'none'
			})
			reject(res);
		}
		opt = Object.assign(_config,opt)
		// console.log("请求接口 => "+opt.url)
		uni.request(opt);

	})
}

/**
 * toast
 */
Utils.msg = function(title = '', param={}, callback) {
	if(!title) return;
	Message({
		message: title,
		type: 'success',
		onClose:callback
	});
}

Utils.previewSrcList = function(attachList){
	var list = [];
	if(attachList instanceof Array){
		for(var index in attachList){
			if(attachList[index].filePath != undefined){
				list.push(attachList[index].filePath)
			}else if(attachList[index].sdua_filePath != undefined){
				list.push(attachList[index].sdua_filePath)
			}
		}
	}else{
		list.push(attachList)
	}
	return list;
}

Utils.warnMsg = function(title = '', callback) {
	if(!title) return;
	Message({
		message: title,
		type: 'warning',
		onClose:callback
	});
}


/**
 * 检查登录
 * @return {Boolean}
 */
Utils.isLogin = function(options={}) {
	const token = uni.getStorageSync('ssid');
	if(token){
		return true;
	}
	if(options.nav !== false){
		uni.navigateTo({
			url: '/pages/auth/login'
		})
	}
	return false;
}

Utils.toFixed = function(number, precision) {
	// console.log(number)
    var str = number + ''
    var len = str.length
    var last = str.substring(len - 1, len)
    if (last == '5') {
        last = '6'
        str = str.substring(0, len - 1) + last
        return (str - 0).toFixed(precision)
    } else {
        return number.toFixed(precision)
    }
}

Utils.isVideo = function(path){
	var dianindex =  path.lastIndexOf('.');
	var subfex = path.substring(dianindex+1);
	if(subfex == 'avi' || subfex == 'wmv' || subfex == 'mpeg' || subfex == 'mp4' || subfex == 'm4v' || subfex == 'mov'
		|| subfex == 'asf' || subfex == 'flv' || subfex == 'f4v' || subfex == 'rmvb' || subfex == 'rm' || subfex == '3gp' || subfex == 'vob'){
		return true;
	}else{
		return false;
	}
}

Date.prototype.Format = function(fmt){
	var o = {
	"M+" : this.getMonth()+1,                 //月份
	"D+" : this.getDate(),                    //日
	"h+" : this.getHours(),                   //小时
	"m+" : this.getMinutes(),                 //分
	"s+" : this.getSeconds(),                 //秒
	"q+" : Math.floor((this.getMonth()+3)/3), //季度
	"S"  : this.getMilliseconds()             //毫秒
	};
	// console.log(fmt)
	if(/(Y+)/.test(fmt)) {
		fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
	}
	for(var k in o) {
		if(new RegExp("(" + k + ")").test(fmt)){
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
		}
	}
	return fmt;
}

Utils.formateTime = function(time, pattern) {
	// console.log(time)
	var d = new Date(parseInt(time)).Format("YYYY-MM-DD hh:mm:ss")
	if(pattern){
		d = new Date(parseInt(time)).Format(pattern);
	}
	return d.toLocaleString();
}

Utils.formateSelfDate = function(time, pattern) {
	time = time.replace(/-/g,'').replace(/\//g,'').replace(':','').replace(/ +/g,'')
	var temp = time.substr(0,4)+"年"+
			time.substr(4,2)+"月"+
			time.substr(6,2)+"日"
	return temp;
}

Utils.mySubStr = function(str,leng) {
	if(str.length > (leng + 3)){
		return str.substring(0,leng)+'...'
	}else{
		return str;
	}

}

Utils.hireModel = [{
	'key': '0',
	'value': '整租'
},
{
		'key': '1',
		'value': '合租'
	}
]

Utils.deliveryModelList = [{
		'key': '0',
		'value': '同城自取'
	},
	{
		'key': '1',
		'value': '同城送货'
	},
	{
		'key': '2',
		'value': '包快递'
	}
]

Utils.txTypeList = [{
		'key': '0',
		'value': '微信零钱'
	},
	{
		'key': '1',
		'value': '支付宝'
	}
]

Utils.locallyHasPro = [{
		'key': '1',
		'value': '本地有现货'
	},
	{
		'key': '0',
		'value': '本地无现货'
	}
]

Utils.storeTypeList = [{
		'key': '0',
		'value': '房产中介/房产经纪人'
	},
	{
		'key': '1',
		'value': '车行'
	},
/* 	{
		'key': '2',
		'value': '导游'
	}, */
	{
		'key': '3',
		'value': '持证导游/旅游公司'
	},
	{
		'key': '4',
		'value': '物流公司'
	},
	{
		'key': '5',
		'value': '餐厅'
	},
	{
		'key': '6',
		'value': '修理厂/车行'
	},
	{
		'key': '7',
		'value': '猎头公司'
	},
	{
		'key': '8',
		'value': '运输公司'
	}
]

Utils.recommendLebels = [{
		'key': '0',
		//'value': '好吃'
		'value': '吃喝'
	},
	{
		'key': '1',
		//'value': '好喝'
		'value': '玩乐'
	},
	{
		'key': '2',
		//'value': '好看'
		'value': '生活'
	},
	{
		'key': '3',
		//'value': '好玩'
		'value': '吐槽'
	},
	{
		'key': '4',
		//'value': '好玩'
		'value': '经验'
	}
]

Utils.experienceLebels = [
	{'key': '0','value': '旅游经验'},
	{'key': '1','value': '租房经验'},
	{'key': '2','value': '买房经验'},
	{'key': '3','value': '租车经验'},
	{'key': '4','value': '买车经验'}
]

Utils.roastLebels = [{
		'key': '0',
		'value': '事件'
	},
	{
		'key': '1',
		'value': '人物'
	},
	{
		'key': '2',
		'value': '产品'
	},
	{
		'key': '3',
		'value': '服务'
	}
]

Utils.inviteModelList = [{
		'key': '0',
		'value': '招聘'
	},
	{
		'key': '1',
		'value': '求职'
	},
	{
		'key': 'nkcor2',
		'value': '猎头公司'
	}
]

Utils.localContactsLabels = [
	{'key': '0','value': '酒店/民宿'},
	{'key': '1','value': '本地旅行社'},
	{'key': '2','value': '汽修/汽配'},
	{'key': '3','value': '中餐厅'},
	{'key': '4','value': 'KTV&按摩、娱乐'},
	{'key': '5','value': '房产中介/房产经纪人'},
	{'key': '6','value': '车行/租车行'},
	{'key': '7','value': '手机、家电、电脑维修'},
	{'key': '8','value': '物流、仓储、快递'},
	{'key': '9','value': '翻译、教育'},
	{'key': '10','value': '理发店'},
	{'key': '11','value': '超市、商店'},
	{'key': '12','value': '鲜花、蛋糕店'},
	{'key': '13','value': '家政、搬家'},
	{'key': '14','value': '医院、诊所'},
	{'key': '15','value': '装修装潢'},
	{'key': '16','value': '银行服务、金融'},
	{'key': '17','value': '婚庆、摄影、视频服务'},
	{'key': '18','value': '律师、移民、保险'},
	{'key': '19','value': '会计事务所'},
	{'key': '20','value': '贸易'},
	{'key': '21','value': '代购/跑腿'},
	{'key': '22','value': '商务咨询、商务中心'},
	{'key': '23','value': '广告、传媒'},
	{'key': '24','value': '美容美甲'},
	{'key': '25','value': '商会、公益机构'},
	{'key': '26','value': '全球领保热线'},
	{'key': '27','value': '本地使馆'},
	{'key': '28','value': '急救电话'},
	{'key': '29','value': '火警电话'},
	{'key': '00','value': '其他'}
]

Utils.inviteLabels = [
	{'key': '0','value': '工程'},
	{'key': '1','value': '财务、金融'},
	{'key': '2','value': '保洁、家政'},
	{'key': '3','value': '科技、电脑'},
	{'key': '4','value': '销售、公关、市场'},
	{'key': '5','value': '贸易'},
	{'key': '6','value': '文娱传媒、设计'},
	{'key': '7','value': '政府、公共事业'},
	{'key': '8','value': '司机'},
	{'key': '9','value': '教育、法律、翻译'},
	{'key': '10','value': '建筑'},
	{'key': '11','value': '仓储、物流'},
	{'key': '12','value': '人事、行政管理'},
	{'key': '13','value': '安保'},
	{'key': '14','value': '厨师、烹饪'},
	{'key': '15','value': '电商'},
	{'key': '16','value': '劳力'},
	{'key': '17','value': '娱乐休闲保健'},
	{'key': '18','value': '体育健身'},
	{'key': '19','value': '房产行业'},
	{'key': '20','value': '汽车行业'},
	{'key': '21','value': '高级管理'},
	{'key': '22','value': '旅游、会展'},
	{'key': '23','value': '餐饮、酒店'},
	{'key': '24','value': '文秘、助理'},
	{'key': '00','value': '其他'}
]

Utils.marriageList = [
					{'key':'0','value':'已婚'},
					{'key':'1','value':'单身'}
				]
				
Utils.xdOrderTypeList = [
					{'key':'2','value':'待付款'},
					{'key':'1','value':'已付款'},
					{'key':'3','value':'确认收货'},
					{'key':'4','value': '取消支付'},
					{'key':'5','value': '支付超时'},
					{'key':'6','value': '确认发货'}
				]
				
Utils.txCheckSttList = [
					{'key':'0','value':'待审核'},
					{'key':'1','value':'审核通过'},
					{'key':'2','value':'审核拒绝'}
				]
				
Utils.putOnSttList = [
					{'key':'0','value':'上架'},
					{'key':'1','value':'下架'}
				]

Utils.fyList = [
					{'key':'0','value':'希望互免'},
					{'key':'1','value':'需要收费'},
					{'key':'2','value':'愿意付费'},
					{'key':'3','value':'费用协商'}
				]

Utils.reqList = [
					{'key':'1','value':'物流带货'},
					{'key':'2','value':'顺风车'},
					{'key':'3','value':'工作机会'},
					{'key':'4','value':'闲置市场'},
					{'key':'5','value':'活动组织'},
					/* {'key':'6','value':'拼团低价'}, */
					{'key':'7','value':'本地折扣'},
					{'key':'9','value':'车辆租售'},
					{'key':'10','value':'本地旅游'},
					{'key':'11','value':'房产仓库'},
					{'key':'12','value':'本地餐厅'},
					{'key':'13','value':'国货出海'},
					{'key':'14','value':'商业机会'},
					{'key':'15','value':'本地通讯'},
					{'key':'16','value':'华人互助'}
				]

Utils.businessOppoLebels = [{
		'key': '0',
		'value': '求助'
	},
	{
		'key': '1',
		'value': '寻求业务'
	},
	{
		'key': '2',
		'value': '执照转让'
	},
	{
		'key': '3',
		'value': '寻求投资'
	},
	{
		'key': '4',
		'value': '寻求代理'
	},
	{
		'key': '5',
		'value': '店铺转让'
	},
	{
		'key': '00',
		'value': '其他'
	}
]

Utils.dqList = [
					{'key':'0','value':'迪拜'},
					{'key':'1','value':'阿布扎比'}
				]

Utils.carModelList = [
					{'key':'0','value':'车找人'},
					{'key':'1','value':'人找车'},
					{
						'key': 'nkcor2',
						'value': '运输公司'
					}
				]

Utils.carryModelList = [
					{'key':'0','value':'货找人'},
					{'key':'1','value':'人找货'},
					{'key':'2','value':'同城跑腿'},
					{
						'key': 'nkcor3',
						'value': '物流公司'
					},
					{
						'key': 'nk4',
						'value': '物流特价'
					},
					{
						'key': 'nk5',
						'value': '物流产品'
					},
					{
						'key': 'nk6',
						'value': '物流专线'
					}
				]

Utils.dbList = [
					{'key':'0','value':'要求对方存入保证金'},
					{'key':'1','value':'不要求对方存入保证金'}
				]

Utils.xzProTypeList = [{
		'key': '0',
		'value': '电子产品/摄影'
	},
	{
		'key': '1',
		'value': '奢侈品'
	},
	{
		'key': '2',
		'value': '家居'
	},
	{
		'key': '3',
		'value': '生活百货'
	},
	{
		'key': '4',
		'value': '服装鞋帽'
	},
	{
		'key': '5',
		'value': '办公用品'
	},
	{
		'key': '6',
		'value': '卡券门票'
	},
	{
		'key': '7',
		'value': '会员'
	},
	{
		'key': '8',
		'value': '箱包'
	},
	{
		'key': '9',
		'value': '宠物'
	},
	{
		'key': '10',
		'value': '体育用品'
	},
	{
		'key': '11',
		'value': '免费赠品'
	},
	{
		'key': '00',
		'value': '其他'
	}
]

Utils.carTransModel = [
					{'key':'0','value':'买车'},
					{'key':'1','value':'卖车'},
					{'key':'2','value':'车招租'},
					{'key':'3','value':'找车租'},
					{
						'key': 'nkcor4',
						'value': '汽车租赁公司'
					},
					{
						'key': 'nkcor5',
						'value': '汽修厂'
					},
					{
						'key': 'nkcor6',
						'value': '车行展厅'
					}
				]

Utils.fangTransModel = [
					{
						'key': '0',
						'value': '我要买房'
					},
					{
						'key': '1',
						'value': '房屋出售'
					},
					{
						'key': '1-1',
						'value': '仓库出售'
					},
					{
						'key': '1-2',
						'value': '办公室出售'
					},
					{
						'key': '2',
						'value': '房屋招租'
					},
					{
						'key': '2-1',
						'value': '仓库招租'
					},
					{
						'key': '2-2',
						'value': '办公室招租'
					},
					{
						'key': '3',
						'value': '合租信息'
					},
					{
						'key': 'nkcor4',
						'value': '房产中介'
					},
					{
						'key': 'nkcor5',
						'value': '房产经纪人'
					}
				]

Utils.unitOfTransList = [
	{'key':'0','value':'天'},
	{'key':'1','value':'周'},
	{'key':'2','value':'月'},
	{'key':'3','value':'年'}
]

//实名认证状态
Utils.certifiList = [
	{'key':'1','value':'审核通过'},
	{'key':'2','value':'审核拒绝'},
	{'key':'3','value':'待审核'}
]

//人工审核状态
Utils.examineSttList = [
	{'key':'1','value':'审核通过'},
	{'key':'2','value':'审核拒绝'},
	{'key':'3','value':'待审核'}
]

//建议意见采纳状态
Utils.proposalSttList = [
	{'key':'1','value':'已采纳'},
	{'key':'2','value':'已忽略'},
	{'key':'3','value':'待处理'}
]

Utils.travelLebels = [{
		'key': '0',
		'value': '机票'
	},
	{
		'key': '1',
		'value': '酒店/民宿'
	},
	{
		'key': '2',
		'value': '接送机'
	},
	{
		'key': '3',
		'value': '包车旅游'
	},
	{
		'key': '4',
		'value': '门票甩卖'
	},
	{
		'key': '5',
		'value': '旅游项目'
	},
	{
		'key': '6',
		'value': '签证'
	},
	{
		'key': 'nkcor7',
		'value': '旅行社'
	},
	{
		'key': 'nkcor8',
		'value': '本地导游'
	},
	{
		'key': '00',
		'value': '其他'
	}
]

Utils.delicacyLebels = [
	{'key': '7','value': '传统中餐'},
	{'key': '8','value': '烧烤'}, 
	{'key': '9','value': '海鲜'}, 
	{'key': '10','value': '火锅'}, 
	{'key': '11','value': '阿拉伯餐'},  
	{'key': '12','value': '西餐'},   
	{'key': '13','value': '酒吧'},  
	{'key': '14','value': '茶馆/咖啡厅'}, 
	{'key': '15','value': '蛋糕店/奶茶店'},
	{
		'key': '6',
		'value': '菜单'
	},
	{
		'key': '0',
		'value': '新品推荐'
	},
	{
		'key': '1',
		'value': '特惠套餐'
	},
	{
		'key': '2',
		'value': '满减活动'
	},
	{
		'key': '3',
		'value': '打折促销'
	},
	{
		'key': '4',
		'value': '赠送礼品'
	},
	{
		'key': '5',
		'value': '环境'
	},
	{
		'key': '00',
		'value': '其他'
	}

]	

Utils.fangLebels = [
	{'key':'0','value':'仓库'},
	{'key':'1','value':'办公室'},
	{'key':'2','value':'住宅'}
]

Utils.fangCx = [
	{'key':'0','value':'东'},
	{'key':'1','value':'南'},
	{'key':'2','value':'西'},
	{'key':'3','value':'北'},
	{'key':'4','value':'东南'},
	{'key':'5','value':'西南'},
	{'key':'6','value':'东北'},
	{'key':'7','value':'西北'},
	{'key':'8','value':'南北'},
	{'key':'9','value':'东西'}
]

Utils.houseTypes = [
	['1室', '2室','3室','4室','5室','6室','7室','8室','9室'],
	['0厅','1厅', '2厅','3厅','4厅','5厅','6厅','7厅','8厅','9厅'],
	['0卫','1卫', '2卫','3卫','4卫','5卫','6卫','7卫','8卫','9卫']
]

Utils.houseTypeW = [
	{'key':'0','value':'0卫'},
	{'key':'1','value':'1卫'},
	{'key':'2','value':'2卫'},
	{'key':'3','value':'3卫'},
	{'key':'4','value':'4卫'},
	{'key':'5','value':'5卫'},
	{'key':'6','value':'6卫'},
	{'key':'7','value':'7卫'},
	{'key':'8','value':'8卫'},
	{'key':'9','value':'9卫'}
]

Utils.houseTypeT = [
	{'key':'0','value':'0厅'},
	{'key':'1','value':'1厅'},
	{'key':'2','value':'2厅'},
	{'key':'3','value':'3厅'},
	{'key':'4','value':'4厅'},
	{'key':'5','value':'5厅'},
	{'key':'6','value':'6厅'},
	{'key':'7','value':'7厅'},
	{'key':'8','value':'8厅'},
	{'key':'9','value':'9厅'}
]

Utils.houseTypeShi = [
	{'key':'0','value':'1室'},
	{'key':'1','value':'2室'},
	{'key':'2','value':'3室'},
	{'key':'3','value':'4室'},
	{'key':'4','value':'5室'},
	{'key':'5','value':'6室'},
	{'key':'6','value':'7室'},
	{'key':'7','value':'8室'},
	{'key':'8','value':'9室'}
]

Utils.sexList = [
	{'key':'all','value':'不限'},
	{'key':'0','value':'男'},
	{'key':'1','value':'女'}
]

Utils.countrysList = [
	{id:'01',name:'中国'},
	{id:'02',name:'阿联酋'},
]

Utils.citysList = [
	{id:'156',name:'中国',child:[{id:'9999',name:'不限'},{id:'0101',name:'北京'},{id:'0102',name:'上海'}]},
	{id:'784',name:'阿联酋',child:[{id:'9999',name:'不限'},{id:'0201',name:'迪拜'},{id:'0202',name:'阿布扎比'},{id:'0203',name:'沙迦'},{id:'0204',name:'哈伊马角'},{id:'0205',name:'富查伊拉'},{id:'0206',name:'乌姆盖万'},{id:'0207',name:'阿治曼'}]},
	{id:'634',name:'卡塔尔',child:[{id:'9999',name:'不限'},{id:'0301',name:'北部区'},{id:'0302',name:'豪尔'},{id:'0303',name:'乌姆希拉勒'},{id:'0304',name:'戴扬'},{id:'0305',name:'赖扬'},{id:'0306',name:'多哈'},{id:'0307',name:'沃克拉'}]},
	{id:'048',name:'巴林',child:[
		{id:'9999',name:'不限'},
		{id:'0401',name:'麦纳麦'},
		{id:'0402',name:'穆哈拉格'},
		{id:'0403',name:'里法'},
		{id:'0404',name:'马迪纳特哈马迪'},
		{id:'0405',name:'阿里'},
		{id:'0406',name:'伊萨'},
		{id:'0407',name:'希特勒拉'},
		{id:'0408',name:'布达伊'},
		{id:'0409',name:'杰迪哈夫斯'},
		{id:'0410',name:'马利基亚'},
		{id:'0411',name:'艾迪利亚'},
		{id:'0412',name:'阿瓦利'},
		{id:'0413',name:'迪拉兹'},
		{id:'0414',name:'希得'},
		{id:'0415',name:'萨尔'},
		{id:'0416',name:'巴尼贾麦拉'}
	]},
	{id:'512',name:'阿曼',child:[{id:'9999',name:'不限'},{id:'0501',name:'马斯喀特'},{id:'0502',name:'萨拉拉'},{id:'0503',name:'苏哈尔'}]},
	{id:'682',name:'沙特',child:[
		{id:'9999',name:'不限'},
		{id:'0601',name:'利雅得'},
		{id:'0602',name:'吉达'},
		{id:'0603',name:'达曼'},
		{id:'0604',name:'胡贝尔'},
		{id:'0605',name:'达兰'},
		{id:'0606',name:'麦加'},
		{id:'0607',name:'麦地那城'},
		{id:'0608',name:'塔伊夫'}
	]},
	{id:'414',name:'科威特',child:[{id:'9999',name:'不限'},{id:'0701',name:'科威特城'},{id:'0702',name:'费尔瓦尼耶市'}]},
	{id:'368',name:'伊拉克',child:[
		{id:'9999',name:'不限'},
		{id:'0801',name:'巴格达'},
		{id:'0802',name:'萨马拉'},
		{id:'0803',name:'巴士拉'},
		{id:'0804',name:'摩苏尔'},
		{id:'0805',name:'法奥'},
		{id:'0806',name:'纳杰夫'},
		{id:'0807',name:'埃尔比勒'},
		{id:'0808',name:'阿盖尔古大'},
		{id:'0809',name:'亚述'},
		{id:'0810',name:'阿韦尔沼泽地'},
		{id:'0811',name:'巴比伦'}
	]},
	{id:'364',name:'伊朗',child:[
		{id:'9999',name:'不限'},
		{id:'0901',name:'德黑兰'},
		{id:'0902',name:'库姆'},
		{id:'0903',name:'中央'},
		{id:'0904',name:'加兹温'},
		{id:'0905',name:'吉兰'},
		{id:'0906',name:'阿尔达比勒'},
		{id:'0907',name:'赞詹'},
		{id:'0908',name:'东亚塞拜疆'},
		{id:'0909',name:'西亚塞拜疆'},
		{id:'0910',name:'库尔德斯坦'},
		{id:'0911',name:'哈马丹'},
		{id:'0912',name:'克尔曼沙汗'},
		{id:'0913',name:'伊拉姆'},
		{id:'0914',name:'洛雷斯坦'},
		{id:'0915',name:'胡齐斯坦'},
		{id:'0916',name:'恰哈马哈勒'},
		{id:'0917',name:'科吉卢耶'},
		{id:'0918',name:'布什尔'},
		{id:'0919',name:'法尔斯'},
		{id:'0920',name:'霍尔木兹甘'},
		{id:'0921',name:'锡斯坦－俾路支斯坦'},
		{id:'0922',name:'克尔曼'},
		{id:'0923',name:'亚兹德'},
		{id:'0924',name:'伊斯法汗'},
		{id:'0925',name:'塞姆南'},
		{id:'0926',name:'马赞达兰'},
		{id:'0927',name:'戈勒斯坦'},
		{id:'0928',name:'北霍拉桑'},
		{id:'0929',name:'霍拉桑'},
		{id:'0930',name:'南霍拉桑'}
	]},
	{id:'586',name:'巴基斯坦',child:[
		{id:'9999',name:'不限'},
		{id:'1001',name:'伊斯兰堡'},
		{id:'1002',name:'卡拉奇'},
		{id:'1003',name:'拉合尔'},
		{id:'1004',name:'拉瓦尔品第'},
		{id:'1005',name:'奎达'},
		{id:'1006',name:'吉尔吉特'}
	]},
	{id:'792',name:'土耳其',child:[
		{id:'9999',name:'不限'},
		{id:'1101',name:'伊斯坦布尔'},
		{id:'1102',name:'伊兹密尔'},
		{id:'1103',name:'布尔萨'},
		{id:'1104',name:'阿达纳'},
		{id:'1105',name:'加济安泰普'},
		{id:'1106',name:'安塔利亚'}
	]},
	{id:'818',name:'埃及',child:[
		{id:'9999',name:'不限'},
		{id:'1201',name:'开罗'},
		{id:'1202',name:'吉萨'},
		{id:'1203',name:'卢克索'},
		{id:'1204',name:'亚历山大港'},
		{id:'1205',name:'沙姆沙伊赫'},
		{id:'1206',name:'阿斯旺'},
		{id:'1207',name:'赫尔格达'},
		{id:'1208',name:'西瓦绿洲'},
		{id:'1209',name:'塞德港'},
		{id:'1210',name:'哈尔加绿洲'},
		{id:'1211',name:'达哈卜'},
		{id:'1212',name:'埃德富'},
		{id:'1213',name:'达赫拉绿洲'},
		{id:'1214',name:'伊斯梅利亚'},
		{id:'1215',name:'康翁波'}
	]},
	{id:'400',name:'约旦',child:[
		{id:'9999',name:'不限'},
		{id:'1301',name:'安曼'},
		{id:'1302',name:'伊尔比德'},
		{id:'1303',name:'亚喀巴'}
	]},
	{id:'688',name:'塞尔维亚',child:[
		{id:'9999',name:'不限'},
		{id:'1401',name:'贝尔格莱德'},
		{id:'1402',name:'诺维萨德'},
		{id:'1403',name:'尼什'},
		{id:'1404',name:'克拉古耶瓦茨'}
	]},
	{id:'422',name:'黎巴嫩',child:[
		{id:'9999',name:'不限'},
		{id:'1501',name:'贝鲁特'},
		{id:'1502',name:'的黎波里'},
		{id:'1503',name:'巴勒贝克'},
		{id:'1504',name:'泰尔'},
		{id:'1505',name:'赛达'}
	]},
	{id:'434',name:'利比亚',child:[
		{id:'9999',name:'不限'},
		{id:'1601',name:'的黎波里'},
		{id:'1602',name:'班加西'},
		{id:'1603',name:'苏尔特'},
		{id:'1604',name:'艾季达比耶'},
		{id:'1605',name:'图卜鲁格'}
	]},
	{id:'012',name:'阿尔及利亚',child:[
		{id:'9999',name:'不限'},
		{id:'1701',name:'阿尔及尔'},
		{id:'1702',name:'奥兰'},
		{id:'1703',name:'君士坦丁'},
		{id:'1704',name:'安纳巴'}
	]},
	{id:'268',name:'格鲁吉亚',child:[
		{id:'9999',name:'不限'},
		{id:'1801',name:'第比利斯'},
		{id:'1802',name:'巴统'},
		{id:'1803',name:'库塔伊西'},
		{id:'1804',name:'波季'},
		{id:'1805',name:'鲁斯塔维'}
	]},
	{id:'398',name:'哈萨克斯坦',child:[
		{id:'9999',name:'不限'},
		{id:'1901',name:'努尔苏丹'},
		{id:'1902',name:'阿拉木图'},
		{id:'1903',name:'卡拉干达'},
		{id:'1904',name:'乌拉尔'}
	]},
	{id:'795',name:'土库曼斯坦',child:[
		{id:'9999',name:'不限'},
		{id:'2001',name:'阿什哈巴德'},
		{id:'2002',name:'巴尔坎纳巴德'},
		{id:'2003',name:'达沙古兹'},
		{id:'2004',name:'土库曼巴希'}
	]}
]

Utils.getDisPlay = function(key,arr){
	let result = arr.find(item => {
		return item.key == key;
	})
	return result.value;
}

Utils.getHoseTypeVal = function(key,arr){
	var arrKeys = [];
	if(key instanceof Array){
		arrKeys = key;
	}else{
		if(key.indexOf('[') >= 0 && key.indexOf(']') >= 0){
			arrKeys = JSON.parse(key)
		}else{
			key = '['.concat(key).concat(']');
			arrKeys = JSON.parse(key)
		}
	}
	return arr[0][arrKeys[0]] + arr[1][arrKeys[1]] + arr[2][arrKeys[2]]
}

Utils.getIndexByParamId = function(key,arr){
	var tempIndex = 0;
	for(var index in arr){
		if(key == arr[index].paramId){
			tempIndex = index;
			break;
		}
	}
	return tempIndex;
}

Utils.getAreaNameByAc = function(key){
	// console.log(key)
	var areaArr;
	if(key instanceof Array){
		areaArr = key
	}else{
		areaArr = JSON.parse(key)
	}
	var areaArr = Utils.getAreaIndexByIds(areaArr);
	return [Utils.citysList[areaArr[0]].name,Utils.citysList[areaArr[0]].child[areaArr[1]].name];
}

Utils.getIndexByKey = function(key,arr){
	var tempIndex = 0;
	for(var index in arr){
		if(key == arr[index].key){
			tempIndex = index;
			break;
		}
	}
	return tempIndex;
}

Utils.MyDateDiff = function(startDate,endDate,unit){
	var startTime = Date.parse(startDate.substr(0,4)+"-"+
			startDate.substr(4,2)+"-"+
			startDate.substr(6,2)+" "+
			startDate.substr(8,2)+":"+
			startDate.substr(10,2)+":"+
			startDate.substr(12,2));

	var endTime = Date.parse(endDate.substr(0,4)+"-"+
			endDate.substr(4,2)+"-"+
			endDate.substr(6,2)+" "+
			endDate.substr(8,2)+":"+
			endDate.substr(10,2)+":"+
			endDate.substr(12,2));

	var dateDiff = endTime - startTime;
	if(unit == 'min'){
		var leave1 = dateDiff % (24 * 3600 * 1000);
		var leave2 = leave1 % (3600 * 1000);
		var minutes = Math.floor(leave2 / (60 * 1000));
		return minutes;
	}
	return null;
}

Utils.getIdByIndex = function(arr){
	var countryIndex = arr[0];
	var cityIndex = arr[1];
	var countryId = Utils.citysList[countryIndex].id;
	var cityId = Utils.citysList[countryIndex].child[cityIndex].id;
	return [countryId,cityId];
}

Utils.getAreaIndexByIds = function(val){
	var ctIndex = 0;
	var cyIndex = 0;
	var arr;
	if(val instanceof Array){
		arr = val
	}else{
		arr = JSON.parse(val);
	}
	var countryId = arr[0];
	var cityId = arr[1];
	for(var countryIndex in Utils.citysList){
		if(countryId == Utils.citysList[countryIndex].id){
			ctIndex =  parseInt(countryIndex)
			break;
		}
	}
	var child = Utils.citysList[ctIndex].child;
	for(var cityIndex in child){
		if(cityId == child[cityIndex].id){
			cyIndex = parseInt(cityIndex);
			break;
		}
	}
	return [ctIndex,cyIndex];
}

Utils.fomatTime = function(valueTime){
	var inittime = valueTime;
	if(valueTime){
		valueTime = Date.parse(valueTime.substr(0,4)+"-"+
				valueTime.substr(4,2)+"-"+
				valueTime.substr(6,2)+" "+
				valueTime.substr(8,2)+":"+
				valueTime.substr(10,2)+":"+
				valueTime.substr(12,2));
	    var newData =  Date.parse(new Date());
	    var diffTime = Math.abs(newData-valueTime);
	    if (diffTime > 7 * 24 * 3600 * 1000) {
	      var date = new Date(valueTime);
	      var y = date.getFullYear();
	      var m = date.getMonth() + 1;
	      m = m < 10 ? ('0' + m) : m;
	      var d = date.getDate();
	      d = d < 10 ? ('0' + d) : d;
	      var h = date.getHours();
	      h = h < 10 ? ('0' + h) : h;
	      var minute = date.getMinutes();
	      var second = date.getSeconds();
	      minute = minute < 10 ? ('1' + minute) : minute;
	      second = second < 10 ? ('0' + second) : second;
	      return  m + '-' + d+' '+h+':'+minute;

	    } else if (diffTime < 7 * 24 * 3600 * 1000 && diffTime > 24 * 3600 * 1000) {
	      // //注释("一周之内");

	      // var time = newData - diffTime;
	      var dayNum = Math.floor(diffTime / (24 * 60 * 60 * 1000));
	      return dayNum + "天前";

	    } else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) {
	      // //注释("一天之内");
	      // var time = newData - diffTime;
	      //var dayNum = Math.floor(diffTime / (60 * 60 * 1000));
	      //return dayNum + "小时前";
		  return inittime.substr(8,2)+":"+
				inittime.substr(10,2);

	    } else if (diffTime < 3600 * 1000 && diffTime > 0) {
	      // //注释("一小时之内");
	      // var time = newData - diffTime;
	      var dayNum = Math.floor(diffTime / (60 * 1000));
		  if(dayNum == 0){
			 return "刚刚";
		  }
	      return dayNum + "分钟前";

	    }else {
			return "刚刚";
		}
	  }
}

Utils.myChooseImage = function(limitFilesNum,fileList,baseUrlpath){
	if(fileList.length >= limitFilesNum){
		Utils.msg('超过最大限制数');
		return;
	}
	wx.chooseImage({
		count:5,
		sizeType: ['original', 'compressed'],
		sourceType: ['album', 'camera'],
		success: (res) => {
			// console.log(res)
			if((limitFilesNum - fileList.length) < res.tempFiles.length){
				Utils.msg('超过最大限制数');
				return;
			}
			uni.showLoading({
			   title: '上传中...',
			   mask: true,
			})
			this.myUploadimage(res,0,fileList,baseUrlpath);
		}
	})
}

Utils.myUploadimage = function(res,i,fileList,baseUrlpath){
	var that = this;
	uni.uploadFile({
		url: baseUrlpath+"addImg",
		filePath: res.tempFiles[i].path,
		name: 'multipartfiles',
		formData: {},
		success: (resp) => {
			var respJson = JSON.parse(resp.data)
			fileList.push({fileId:respJson.fileId,path:respJson.path});
			// console.log(fileList)
		},
		complete: (resp) => {
			i++;
			if(i == res.tempFiles.length){
				uni.hideLoading();
			}else{
				that.uploadimg(res,i,fileList,baseUrlpath);
			}
		}
	})
}

Utils.myChooseVideo = function(limitFilesNum,fileList,baseUrlpath){
	if(fileList.length >= limitFilesNum){
		Utils.msg('超过最大限制数');
		return;
	}
	// console.log(this)
	uni.chooseMedia({
		count:1,
		mediaType: ["video"],
		sizeType: ['original', 'compressed'],
		sourceType: ['album', 'camera'],
		success: (res) => {
			// console.log(res)
			if((limitFilesNum - fileList.length) < 1){
				Utils.msg('超过最大限制数');
				return;
			}
			wx.showLoading({
			   title: '上传中...',
			   mask: true,
			})
			this.myUploadvideo(res,0,fileList,baseUrlpath);
		}
	})
}

Utils.myUploadvideo = function(res,i,fileList,baseUrlpath){
	var duration = res.tempFiles[0].duration;
	var tempFilePath = res.tempFiles[0].tempFilePath;
	var thumbTempFilePath = res.tempFiles[0].thumbTempFilePath;
	if(duration > 10){
		Utils.msg('视频不能超10秒');
		return;
	}
	uni.uploadFile({
		url: baseUrlpath+"addImg",
		filePath: tempFilePath,
		name: 'multipartfiles',
		formData: {},
		success: (resp) => {
			var respJson = JSON.parse(resp.data)
			var fileId = respJson.fileId;
			var path = respJson.path;
			uni.uploadFile({
				url: baseUrlpath+"addImg",
				filePath: thumbTempFilePath,
				name: 'multipartfiles',
				formData: {},
				success: (resps) => {
					var respsJson = JSON.parse(resps.data)
					var thumbPath = respsJson.path;
					fileList.push({fileId:fileId,path:path,thumbPath:thumbPath});
					// console.log(fileList)
				},
				complete: (resp) => {
					uni.hideLoading();
				}
			})
		},
		complete: (resp) => {

		}
	})
}

Utils.goBack = function(){
  router.go(-1);
}
export default Utils;
