import axios from 'axios'
import { Message } from "element-ui";
import router from '../router/index.js'
import store from '../store/index.js'
axios.defaults.withCredentials = true
const service = axios.create({
	baseURL: process.env.BASE_API, // url = base url + request url
	timeout: 60000,
	withCredentials: true,
	crossDomain: true
})


// request拦截器
service.interceptors.request.use(
	config => {
		// do something before request is sent
		// config.headers['appKey'] = getToken()
		// console.log(config.url);
		return config
	},
	error => {
		// do something with request error
		// console.log(error) // for debug
		return Promise.reject(error)
	}
)


// response拦截器
service.interceptors.response.use(
	response => {
		/**
		 * 1.code为非000000是抛错 可结合自己业务进行修改
		 * 2.response 是 http 封装的实体类，response.data 使我们真正的响应数据
		 */
		const res = response.data
		// console.log("拦截前的response:", response);
		if (res.ec == 'ECC989') {
			console.log('登录信息异常')
			Message({
				message: '登录超时，请重新登录系统',
				type: 'error',
				onClose:function(){
					localStorage.removeItem('accesstoken')
					localStorage.removeItem('userInfo')
					localStorage.removeItem('fayanboId')
					if (router.currentRoute.name != 'main') {
						router.push({
							name: 'main'
						})
					}
					store.dispatch('setLoginDialogvisible', true)
				}
			});
		}else if(res.ec == 'ECC988'){
			Message({
				message: '请登录系统',
				type: 'error'
			});
			router.push({
				name: 'main'
			})
			store.dispatch('setLoginDialogvisible', true)
		}
		return res
	},
	error => {
		// console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)
export default service
