<template>
	<div class="container">
		<div class="flex-between headwarp">
			<div class="head-item active">1.选择类别</div>
			<div class="head-item">2.填写信息</div>
			<div class="head-item">3.完成发布</div>
		</div>
		<div class="cont-body">
			<!-- <div class="classfy-warp">
				<div class="classfy-item" @click="goPublishStore">企业自荐</div>
			</div>
			<div class="divider"></div> -->
			<div class="classfy-warp">
				<div class="classfy-item" @click="goPublishRecomm">热点</div>
			</div>
			<div class="divider"></div>
			<div class="classfy-warp">
				<div v-for="(item,index) in reqTypes" :key="item.key" class="classfy-item" @click="goPublish(item.key)">
					{{item.value}}
				</div>
			</div>
		</div>
		<el-dialog title="免费发布" :visible="loginDialog" @close="handledialogClose" width="60%">
			<div class="pub_item_warp">
				<div class="item" @click="goPublishRecomm">
					<div class="icon el-icon-hot"></div>
					<div class="txt">热点</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">吃喝，玩乐，生活，吐槽，经验</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('1')">
					<div class="icon el-icon-daihuo"></div>
					<div class="txt">物流带货</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">货找人，人找货，同城跑腿，物流公司，物流特价，物流产品，物流专线</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('2')">
					<div class="icon el-icon-shunfengche"></div>
					<div class="txt">顺风车</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">车找人，人找车，运输公司</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('3')">
					<div class="icon el-icon-zhaopin"></div>
					<div class="txt">工作机会</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">招聘，求职，猎头公司</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('4')">
					<div class="icon el-icon-qingcang"></div>
					<div class="txt">闲置市场</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">电子产品/摄影，奢侈品，家居，生活百货，服装鞋帽，办公用品，卡券门票，会员，箱包，宠物，体育用品，免费赠品</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('5')">
					<div class="icon el-icon-huodong"></div>
					<div class="txt">活动组织</div>
					<div class="temp">
						<div class="cover-box">
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('7')">
					<div class="icon el-icon-tejia"></div>
					<div class="txt">本地折扣</div>
					<div class="temp">
						<div class="cover-box">
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('9')">
					<div class="icon el-icon-car"></div>
					<div class="txt">车辆租售</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">买车，卖车，车招租，找车租，汽车租赁公司，汽修厂，车行展厅</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('10')">
					<div class="icon el-icon-luyou"></div>
					<div class="txt">本地旅游</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">机票,酒店/民宿,接送机,包车旅游,门票甩卖,旅游项目,签证,旅行社,本地导游，酒店/民宿，接送机，包车旅游，门票甩卖，旅游项目，签证，旅行社，本地导游</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('11')">
					<div class="icon el-icon-house2"></div>
					<div class="txt">房产仓库</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">我要买房，房屋出售，仓库出售，办公室出售，房屋招租，仓库招租，办公室招租，合租信息，房产中介，房产经纪人</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('12')">
					<div class="icon el-icon-foot"></div>
					<div class="txt">本地餐厅</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">传统中餐，烧烤，海鲜，火锅，阿拉伯餐，西餐，酒吧，茶馆/咖啡厅，蛋糕店/奶茶店，菜单，新品推荐，特惠套餐，满减活动，打折促销，赠送礼品</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('13')">
					<div class="icon el-icon-interact"></div>
					<div class="txt">国货出海</div>
					<div class="temp">
						<div class="cover-box">
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('14')">
					<div class="icon el-icon-roast"></div>
					<div class="txt">商业机会</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">求助，寻求业务，执照转让，寻求投资，寻求代理，店铺转让</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('15')">
					<div class="icon el-icon-tel"></div>
					<div class="txt">本地通讯</div>
					<div class="temp">
						<div class="cover-box">
							<div class="desc">酒店/民宿，本地旅行社，汽修/汽配，中餐厅，KTV&按摩、娱乐，房产中介/房产经纪人，车行/租车行，手机、家电、电脑维修，物流、仓储...</div>
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
				<div class="item" @click="goPublish('16')">
					<div class="icon el-icon-rate"></div>
					<div class="txt">华人互助</div>
					<div class="temp">
						<div class="cover-box">
							<div class="btn">立即发布</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				reqTypes: [],
				loginDialog: true
			}
		},
		created() {
			this.reqTypes = JSON.parse(JSON.stringify(this.$util.reqList));
			// console.log(this.reqTypes)
		},
		methods: {
			handledialogClose() {
				this.loginDialog = false
			},
			goPublishRecomm() {
				this.$router.push({
					name: '/recommend/publish'
				});
			},
			goPublishStore() {
				this.$router.push({
					name: '/storeRecommend/publish'
				});
			},
			goPublish(reqType) {
				var url = '';
				if (reqType == '1') {
					url = 'pubcommerce'
				} else if (reqType == '2') {
					url = 'pubsfc'
				} else if (reqType == '3') {
					url = 'pubrecruit'
				} else if (reqType == '4') {
					url = 'pubsale'
				} else if (reqType == '5') {
					url = 'pubactivity'
				} else if (reqType == '7') {
					url = 'pubDiscounts'
				} else if (reqType == '9') {
					url = 'publocomotive'
				} else if (reqType == '10') {
					url = 'pubtravel'
				} else if (reqType == '11') {
					url = 'pubhouse'
				} else if (reqType == '12') {
					url = 'pubDelicacy'
				} else if (reqType == '13') {
					url = 'pubCnyGoodExpt'
				} else if (reqType == '14') {
					url = 'pubRoast'
				} else if (reqType == '15') {
					url = 'pubTel'
				} else if (reqType == '16') {
					url = 'pubRate'
				}
				this.$router.push({
					name: url,
					query: {
						reqType: reqType
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	::v-deep .el-dialog {
		background: #f4f4f4;
	}

	.pub_item_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.item {
			height: 10.5rem;
			width: calc(20%);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			//background-color: #fff;
			//margin: 0.2rem;
			border-radius: 0.5rem;

			.icon {
				height: 3.125rem;
				width: 3.125rem;
			}

			.txt {
				font-size: 1rem;
				margin-top: 1.5rem;
				font-weight: 700;
			}

			.temp {
				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				top: -12.5rem;
				text-align: center;
				color: #fff;
				transition: all .7s ease;
				z-index: 10;
				.cover-box {
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 0 0.125rem;
					.btn {
						display: inline-block;
						font-size: 14px;
						color: #fff;
						border: 1px solid #fff;
						padding: 4px 10px;
					}
					.desc {
						font-size: 10px;
						margin-bottom: 14px;
						text-align: left;
						padding: 0 0.25rem;
						line-height: 1.125rem;
					}
				}
			}
		}

		.item:hover {
			.temp {
				top: 0;
				background: rgba(0, 0, 0, .7);
			}
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;

		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
				cursor: pointer;
			}

			.active {
				border-bottom: 2px solid #C88854;
			}
		}

		.classfy-warp {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			.classfy-item {
				text-align: left;
				line-height: 36px;
				cursor: pointer;
				width: 150px;
			}

			.classfy-item:hover {
				color: #C88854;
				font-weight: bold;
			}
		}

		.cont-body {
			margin-top: 20px;

			.divider {
				background-color: #DCDFE6;
				display: block;
				height: 1px;
				width: 150px;
				margin: 8px 0;
			}
		}
	}
</style>