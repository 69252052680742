import { render, staticRenderFns } from "./chujia.vue?vue&type=template&id=496f9ade&scoped=true&"
import script from "./chujia.vue?vue&type=script&lang=js&"
export * from "./chujia.vue?vue&type=script&lang=js&"
import style0 from "./chujia.vue?vue&type=style&index=0&id=496f9ade&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496f9ade",
  null
  
)

export default component.exports