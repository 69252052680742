/**
 * Created by czx on 2020/1/17.
 */
import request from './request'
import qs from 'qs'
import store from '../store/index.js'
import {
	Message
} from "element-ui";
import JSEncrypt from "encryptlong";
// qs.parse()将URL解析成对象的形式;qs.stringify()将对象 序列化成URL的形式，以&进行拼接

const Http = {}
Http.request = request

const publicKeyStr = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYYyxpwyI5XQj4SI09z99aLDtjBYNI3XzR+1p/" +
	"6fD1nwFtzBJcHQ/2AuefzxRvb5IbDRRRI46waXwWBeYHs/yPv5YvuSu5KlN1meaI9G7Gb+grnb2c" +
	"YGqcplyDddYOccKYxWAQ80ApUAqnoDn5YbSGAr9NKsznTjKII3gnuw6UaQIDAQAB";

const baseUrl = ""

/**
 * @desc Http表单方式提交
 * @param url 请求的url 参数  /loginAsync.do
 * @parrm method 请求方法类型 类似post get
 * @param params {} 参数对象  data: {username: username, password: password},
 */
Http.commitForm = function(url, params, config = {}) {
	const accesstoken = localStorage.getItem('accesstoken');
	url = baseUrl.concat(url)
	const _config = {
		url,
		method: 'post',
		data: params,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Bearer ' + accesstoken
		},
		transformRequest: params => qs.stringify(params, {
			arrayFormat: 'repeat'
		}) // 修改数组传递格式
	}
	config = Object.assign(config, _config)
	return request(config)
}

Http.commitFile = function(url, params, config = {}) {
	const accesstoken = localStorage.getItem('accesstoken');
	params.sessionUserId = store.state.userInfo.fayanboId;
	url = baseUrl.concat(url)
	const _config = {
		url,
		method: 'POST',
		data: params,
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': 'Bearer ' + accesstoken
		} // 修改数组传递格式
	}
	config = Object.assign(config, _config)
	// console.log(config)
	return request(config)
}

/*
 * JSON格式的接口，默认为post请求
 * 传入 config={method: 'get'}则变为get请求
 */
Http.Json = function(url, params, config = {}) {
	const accesstoken = localStorage.getItem('accesstoken');
	url = baseUrl.concat(url)
	const _config = {
		method: 'post',
		url: url,
		data: params,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + accesstoken
		}
	}
	config = Object.assign(_config, config)
	return request(config)
}

Http.JsonPic = function(url, params) {
	url = baseUrl.concat(url)
	return request.post(url, params)
}

Http.post = function(url, params, config = {}) {
	const accesstoken = localStorage.getItem('accesstoken');
	url = baseUrl.concat(url)
	const _config = {
		method: 'post',
		url: url,
		data: params,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Authorization': 'Bearer ' + accesstoken
		},
		withCredentials: true,
		crossDomain: true,
		transformRequest: function(params) {
			return JSON.stringify(params, {
				arrayFormat: 'repeat'
			}) // 修改数组传递格式
		}
	}
	config = Object.assign(_config, config)
	//// console.log(config);
	return request(config)
}

Http.postWithUInfo = function(url, params, config = {}) {
	const accesstoken = localStorage.getItem('accesstoken');
	url = baseUrl.concat(url)
	const _config = {
		method: 'post',
		url: url,
		data: params,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Authorization': 'Bearer ' + accesstoken
		},
		withCredentials: true,
		crossDomain: true,
		transformRequest: function(params) {
			return JSON.stringify(params, {
				arrayFormat: 'repeat'
			}) // 修改数组传递格式
		}
	}
	config = Object.assign(_config, config)
	//// console.log(config);
	return request(config)
}

Http.get = function(url, params, config = {}) {
	url = baseUrl.concat(url)
	const accesstoken = localStorage.getItem('accesstoken');
	//params.sessionUserId = store.state.userInfo.fayanboId;
	console.log(params)
	const jsRsa = new JSEncrypt();
	jsRsa.setPublicKey(publicKeyStr);
	const jmParams = jsRsa.encryptLong(JSON.stringify(params));
	console.log(jmParams)
	const _config = {
		method: 'get',
		url: url,
		params: {req:jmParams},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Bearer ' + accesstoken
		},
		withCredentials: true,
		crossDomain: true
		/* paramsSerializer: function(params) {
			return qs.stringify(params, {
				arrayFormat: 'repeat'
			}) // 修改数组传递格式
		} */
	}
	config = Object.assign(_config, config)
	console.log('getetetettetete')
	const initres = request(config)
	console.log(initres)
	return initres
}

Http.getWithUInfo = function(url, params, config = {}) {
	url = baseUrl.concat(url)
	const accesstoken = localStorage.getItem('accesstoken');
	console.log(JSON.stringify(params))
	const jsRsa = new JSEncrypt();
	jsRsa.setPublicKey(publicKeyStr);
	const jmParams = jsRsa.encryptLong(encodeURIComponent(JSON.stringify(params)));
	console.log(jmParams)
	const _config = {
		method: 'get',
		url: url,
		params: {req:jmParams},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Bearer ' + accesstoken
		},
		withCredentials: true,
		crossDomain: true
		/* paramsSerializer: function(params) {
			return qs.stringify(params, {
				arrayFormat: 'repeat'
			}) // 修改数组传递格式
		} */
	}
	config = Object.assign(_config, config)
	return request(config)
}

/*Http.mapUrl = function(url, params) {
  if (typeof params === 'object') {
    params = encodeUrlParam(params)
  } else {
    params = null
  }
  if (params) {
    if (url.indexOf('?') >= 0) {
      url += '&' + params
    } else {
      url += '?' + params
    }
  }
  if (!process.env.BASE_API || process.env.BASE_API === '/' || process.env.BASE_API === '') {
    return window.location.origin + url
  }
  return process.env.BASE_API + url
}*/

JSEncrypt.prototype.encryptLong = function (string) {
    var k = this.getKey();
    try {
        var ct = "";
        //RSA每次加密117bytes，需要辅助方法判断字符串截取位置
        //1.获取字符串截取点
        var bytes = new Array();
        bytes.push(0);
        var byteNo = 0;
        var len, c;
        len = string.length;
        var temp = 0;
        for (var i = 0; i < len; i++) {
            c = string.charCodeAt(i);
            if (c >= 0x010000 && c <= 0x10FFFF) {  //特殊字符，如Ř，Ţ
                byteNo += 4;
            } else if (c >= 0x000800 && c <= 0x00FFFF) { //中文以及标点符号
                byteNo += 3;
            } else if (c >= 0x000080 && c <= 0x0007FF) { //特殊字符，如È，Ò
                byteNo += 2;
            } else { // 英文以及标点符号
                byteNo += 1;
            }
            if ((byteNo % 117) >= 114 || (byteNo % 117) == 0) {
                if (byteNo - temp >= 114) {
                    bytes.push(i);
                    temp = byteNo;
                }
            }
        }
        //2.截取字符串并分段加密
        if (bytes.length > 1) {
            for (var i = 0; i < bytes.length - 1; i++) {
                var str;
                if (i == 0) {
                    str = string.substring(0, bytes[i + 1] + 1);
                } else {
                    str = string.substring(bytes[i] + 1, bytes[i + 1] + 1);
                }
                var t1 = k.encrypt(str);
                ct += t1;
            }
            ;
            if (bytes[bytes.length - 1] != string.length - 1) {
                var lastStr = string.substring(bytes[bytes.length - 1] + 1);
                ct += k.encrypt(lastStr);
            }
            return hex2b64(ct);
        }
        var t = k.encrypt(string);
        var y = hex2b64(t);
        return y;
    } catch (ex) {
        console.log(ex);
        return false;
    }
};
function hex2b64(h) {
    var b64map="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var b64padchar="=";
    var i;
    var c;
    var ret = "";
    for(i = 0; i+3 <= h.length; i+=3) {
        c = parseInt(h.substring(i,i+3),16);
        ret += b64map.charAt(c >> 6) + b64map.charAt(c & 63);
    }
    if(i+1 == h.length) {
        c = parseInt(h.substring(i,i+1),16);
        ret += b64map.charAt(c << 2);
    }
    else if(i+2 == h.length) {
        c = parseInt(h.substring(i,i+2),16);
        ret += b64map.charAt(c >> 2) + b64map.charAt((c & 3) << 4);
    }
    while((ret.length & 3) > 0) ret += b64padchar;
    return ret;
}


export default Http
