<template>

	<div class="comp-req-warp" @click="goToDetail(item)">
		<div class="atta-img">
			<template v-if="item.attachlist.length > 0">
				<video :src="item.attachlist[0].sdra_filePath" controls="controls" loop="loop" @click.stop=""
					v-if="$util.isVideo(item.attachlist[0].sdra_filePath)" :id="item.attachlist[0].sdra_fileid" @play="play(item.attachlist[0].sdra_fileid)"></video>
				<el-image fit="cover" lazy :src="baseUrlpath + item.attachlist[0].sdra_filePath" v-else></el-image>
			</template>
			<template v-else>
				<el-image fit="cover" lazy :src="defalutImage" ></el-image>
			</template>
			<div class="addr">
				<div class="flex-start">
					<div class="icon"></div>
					<div class="txt" v-if="(item.sdr_reqType == '1' && item.sdr_carry_model.startsWith('nkcor')) ||
						(item.sdr_reqType == '2' && item.sdr_sfcar_model.startsWith('nkcor')) || 
						(item.sdr_reqType == '3' && item.sdr_invite_model.startsWith('nkcor')) || 
						(item.sdr_reqType == '9' && item.sdr_car_transModel.startsWith('nkcor')) || 
						(item.sdr_reqType == '10' && item.sdr_travel_tag.startsWith('nkcor')) || 
						(item.sdr_reqType == '11' && item.sdr_fang_transModel.startsWith('nkcor'))">
						{{item.sdr_storeCorAddr}}
					</div>
					<div class="txt" v-else-if="item.sdr_reqType == '12'">{{item.sdr_delicacy_place}}</div>
					<div class="txt" v-else-if="item.sdr_reqType == '11'">{{item.sdr_fang_communityName || item.sdr_addrDetail}}</div>
					<div class="txt" v-else-if="item.sdr_reqType == '5'">{{item.sdr_activity_place}}</div>
					<div class="txt" v-else>
						<label class="margr4">{{$util.getAreaNameByAc(item.sdr_dqmodel)[0]}}</label>
						<template v-if="item.sdr_dqmodel.indexOf('9999') < 0">
							<label class="margr4">·</label>
							<label>{{$util.getAreaNameByAc(item.sdr_dqmodel)[1]}}</label>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="req-cont">
			<div class="req-cont-head">
				<div class="stick_top_warp" v-if="item.isStickTop == '1'">
					<div class="icon_stick_top"></div>
				</div>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '1'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_carry_model,$util.carryModelList)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '3'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_invite_model,$util.inviteModelList)}}</label>
					<template v-if="!item.sdr_invite_model.startsWith('nkcor')">
						<label class="margr4">|</label>
						<label v-if="item.sdr_invite_model == '0'">{{item.sdr_invite_jobName}}</label>
						<label v-else>{{$util.getDisPlay(item.sdr_invite_inviteTypeIndex,$t('basic.inviteLabels'))}}</label>
					</template>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '2'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_sfcar_model,$util.carModelList)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '5'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{item.sdr_activity_name}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '4'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.mySubStr(item.sdr_proName,10)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '7'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.mySubStr(item.sdr_discount_proName,10)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '9'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_car_transModel,$t('basic.carTransModel'))}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '10'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_travel_tag,$util.travelLebels)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '11'">
					<!-- <template v-if="!$util.isNotEmpty(item.sdr_fang_hireModel)">
						<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
						<label class="margr4">|</label>
					</template> -->
					<label class="margr4">{{$util.getDisPlay(item.sdr_fang_transModel,$t('basic.fangTransModel'))}}</label>
					<!-- <template v-if="!item.sdr_fang_transModel.startsWith('nkcor')">
						<label class="margr4">|</label>
						<label>{{$util.getDisPlay(item.sdr_fang_tag,$util.fangLebels)}}</label>
						<template v-if="$util.isNotEmpty(item.sdr_fang_hireModel) && (item.sdr_fang_transModel == '2' || item.sdr_fang_transModel == '3')">
							<label class="margr4 margl4">|</label>
							<label class="">{{$util.getDisPlay(item.sdr_fang_hireModel,$t('basic.hireModel'))}}</label>
						</template>
					</template> -->
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '12'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_delicacy_tag,$util.delicacyLebels)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '13'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{item.sdr_cnyGood_proName}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '14'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label>
					<label>{{$util.getDisPlay(item.sdr_roast_tagIndex,$util.businessOppoLebels)}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '15'">
<!-- 					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
					<label class="margr4">|</label> -->
					<label class="margr4">{{$util.getDisPlay(item.sdr_contact_tagIndex,$t('basic.localContactsLabels'))}}</label>
					<label class="margr4">|</label>
					<label>{{item.sdr_contact_name}}</label>
				</label>
				<label class="req-tit margt6" v-if="item.sdr_reqType == '16'">
					<label class="margr4">{{$util.getDisPlay(item.sdr_reqType,$t('basic.reqList'))}}</label>
				</label>
			</div>
			<div class="req-cont-body">
				<div class="flex-column-start" v-if="item.sdr_reqType == '11'">
					<template v-if="item.sdr_fang_transModel.startsWith('nk')">
						<div class="item_mb margb8 req-contmsg" v-if="item.sdr_fang_transModel.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
						<div class="flex-start margb8" v-if="item.sdr_fang_transModel.startsWith('nkcor')">
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
						</div>
						<div class="flex-start margb8" v-else>
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
						</div>
					</template>
					<template v-else>
						<div class="flex-start mindel-h margb8">
							<div class="req-tag">{{item.sdr_fang_measure}}平</div>
							<template v-if="['0','1','2','3'].includes(item.sdr_fang_transModel)">
								<div class="req-tag">{{$util.getHoseTypeVal(item.sdr_fang_houseType,$util.houseTypes)}}</div>
							</template>
						</div>
						<div class="flex-start margb8">
							<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),30)}}</div>
						</div>
						<div class="flex-start margb8">
							<div class="flex-center-baseline nail-price">
								<div class="currency_type">{{item.currency_symbol}}</div>
								<div class="amt">{{item.sdr_fang_price}}</div>
								<div class="flex-start amt-unit"
									v-if="['2','2-1','2-2','3'].includes(item.sdr_fang_transModel)">
									<div class="unit-split">/</div>
									<div>{{$util.getDisPlay(item.sdr_fang_transUnit,$util.unitOfTransList)}}</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div v-if="item.sdr_reqType == '1'">
					<div class="rib-nail">
						<template v-if="item.sdr_carry_model.startsWith('nk')">
							<div class="item_mb margb8 req-contmsg" v-if="item.sdr_carry_model.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
							<div class="flex-start margb8" v-if="item.sdr_carry_model.startsWith('nkcor')">
								<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
							</div>
							<div class="flex-start margb8" v-else>
								<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
							</div>
						</template>
						<template v-else>
							<div class="flex-start margb8" v-if="item.sdr_carry_model == '0' || item.sdr_carry_model == '1'">
								<div class="carry_from_place">{{item.sdr_carry_fromWhere}}</div>
								<div class="icon_fly"></div>
								<div class="carry_to_place">{{item.sdr_carry_toWhere}}</div>
							</div>
							<div class="flex-start margb8">
								<div v-if="item.sdr_carry_model == '0' || item.sdr_carry_model == '2'" class="margr10">{{item.sdr_carry_proName}}</div>
								<div class="margr10" v-if="item.sdr_carry_model == '1'">
									<div >{{$util.formateSelfDate(item.sdr_carry_limittime)}}</div>
								</div>
								<div v-if="item.sdr_carry_model == '1'" class="margr10">可带</div>
								<div class="flex-start margr10">
									<div>{{item.sdr_carry_weight}}</div>
									<div>kg</div>
								</div>
							</div>
							<div class="flex-start margb8">
								<div class="flex-center-baseline nail-price">
									<div class="currency_type">{{item.currency_symbol}}</div>
									<div class="amt">{{item.sdr_carry_initPrice}}</div>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '2'">
					<template v-if="item.sdr_sfcar_model.startsWith('nk')">
						<div class="item_mb margb8 req-contmsg" v-if="item.sdr_sfcar_model.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
						<div class="flex-start margb8" v-if="item.sdr_sfcar_model.startsWith('nkcor')">
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
						</div>
						<div class="flex-start margb8" v-else>
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
						</div>
					</template>
					<template v-else>
						<div class="flex-start margb8">
							<div class="sfc-place-from">{{item.sdr_sfcar_fromWhere}}</div>
							<div class="icon_carto"></div>
							<div class="sfc-place-to">{{item.sdr_sfcar_toWhere}}</div>
						</div>
						<div class="flex-start margb8">
							<div class="margr10">出发时间:</div>
							<div>{{item.sdr_sfcar_gotime}}</div>
						</div>
						<div class="flex-start margb8">
							<div class="flex-center-baseline nail-price">
								<div class="currency_type">{{item.currency_symbol}}</div>
								<div class="amt">{{item.sdr_sfcar_initPrice}}</div>
							</div>
						</div>
					</template>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '5'">
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '10'">
					<template v-if="item.sdr_travel_tag.startsWith('nk')">
						<div class="item_mb margb8 req-contmsg" v-if="item.sdr_travel_tag.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
						<div class="flex-start margb8" v-if="item.sdr_travel_tag.startsWith('nkcor')">
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
						</div>
						<div class="flex-start margb8" v-else>
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
						</div>
					</template>
					<template v-else>
						<div v-if="item.sdr_travel_tag == '0'">
							<div class="flex-start margb8">
								<div>{{item.sdr_travel_fromWhere}}</div>
								<div style="margin: 0 8px;">一</div>
								<div>{{item.sdr_travel_toWhere}}</div>
							</div>
							<div class="flex-start margb8">
								<div class="icon_fly"></div>
								<div class="travelDate">{{item.sdr_travel_limitdate}}</div>
							</div>
						</div>
						<div v-if="item.sdr_travel_tag == '1' || item.sdr_travel_tag == '4'">
							<div class="flex-start margb8">{{item.sdr_travel_hotelName}}</div>
							<div class="flex-start margb8">
								<div class="icon_ticket"></div>
								<div class="travelDate">{{item.sdr_travel_serviceDate}}</div>
							</div>
						</div>
						<div class="flex-start margb8"
							v-if="item.sdr_travel_tag != '1' && item.sdr_travel_tag != '4' && item.sdr_travel_tag != '0'">
							<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
						</div>
						<div class="flex-start margb8">
							<div class="flex-center-baseline nail-price">
								<div class="currency_type">{{item.currency_symbol}}</div>
								<div class="amt">{{item.sdr_travel_price}}</div>
							</div>
						</div>
					</template>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '12'">
					<div class="flex-start margb8 item_mb">
						{{item.sdr_restaurant_name}}
					</div>
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
					</div>
					<div class="flex-start margb8" v-if="item.sdr_delicacy_price.length > 0">
						<div class="flex-center-baseline nail-price">
							<div class="currency_type">{{item.currency_symbol}}</div>
							<div class="amt">{{item.sdr_delicacy_price}}</div>
						</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '7'">
					<div class="flex-start margb8 item_mb">
						{{item.sdr_discount_shopName}}
					</div>
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_discount_proDesc),25)}}</div>
					</div>
					<div class="flex-start margb8">
						<div class="flex-center-baseline nail-price">
							<div class="currency_type">{{item.currency_symbol}}</div>
							<div class="amt">{{item.sdr_discount_proPrice}}</div>
						</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '13'">
					<div class="flex-start margb8 item_mb">
						{{item.sdr_cnyGood_corName}}
					</div>
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
					</div>
					<div class="flex-start margb8">
						<div class="flex-center-baseline nail-price">
							<div class="currency_type">{{item.currency_symbol}}</div>
							<div class="amt">{{item.sdr_cnyGood_proPrice}}</div>
						</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '15'">
					<template v-if="['26','27','28','29'].includes(item.sdr_contact_tagIndex)">
						<div class="reqNail mindel-h">TEL:{{item.sdr_contact_PhoneNum}}</div>
					</template>
					<template v-else>
<!-- 						<div class="flex-start margb8 item_mb">
							{{item.sdr_contact_name}}
						</div> -->
						<div class="flex-start margb8">
							<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
						</div>
					</template>
				</div>
				<div class="flex-column-start"
					v-if="item.sdr_reqType == '14' || item.sdr_reqType == '16'">
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '9'">
					<template v-if="item.sdr_car_transModel.startsWith('nk')">
						<div class="item_mb margb8 req-contmsg" v-if="item.sdr_car_transModel.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
						<div class="flex-start margb8" v-if="item.sdr_car_transModel.startsWith('nkcor')">
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
						</div>
						<div class="flex-start margb8" v-else>
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
						</div>
					</template>
					<template v-else>
						<div class="flex-start tag_warp margb8"
							v-if="item.sdr_car_transModel == '1' || item.sdr_car_transModel == '2'">
							<div class="req-tag">{{item.sdr_car_brand}}</div>
							<div class="req-tag">{{item.sdr_car_buyYear}} 年</div>
							<div class="req-tag">{{item.sdr_car_kilometre}} 公里</div>
						</div>
						<div class="flex-start margb8">
							<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
						</div>
						<div class="flex-start margb8">
							<div v-if="item.sdr_car_offerType == '0'" class="nail-price">可以协商</div>
							<div class="flex-center-baseline nail-price" v-if="item.sdr_car_offerType == '1'">
								<div class="currency_type">{{item.currency_symbol}}</div>
								<div class="amt">{{item.sdr_car_price}}</div>
								<div class="amt-unit"
									v-if="item.sdr_car_transModel == '2' || item.sdr_car_transModel == '3'">
									<div class="unit-split">/</div>
									<div class="unit">{{$util.getDisPlay(item.sdr_car_transUnit,$util.unitOfTransList)}}
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '4'">
					<div class="flex-start margb8">
						<div class="reqNail mindel-h">{{$util.mySubStr($util.filterHtml(item.sdr_contents),25)}}</div>
					</div>
					<div class="flex-start nail-price margb8">
						<div class="flex-center-baseline">
							<div class="currency_type">{{item.currency_symbol}}</div>
							<div class="amt">{{item.sdr_proNewPrice}}</div>
						</div>
						<div class="op flex-start">
							<div class="margr4">新品</div>
							<div class="flex-center-baseline">
								<div>{{item.currency_symbol}}</div>
								<div>{{item.sdr_proOldPrice}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex-column-start" v-if="item.sdr_reqType == '3'">
					<template v-if="item.sdr_invite_model.startsWith('nk')">
						<div class="item_mb margb8 req-contmsg" v-if="item.sdr_invite_model.startsWith('nkcor')">{{item.sdr_storeCorName}}</div>
						<div class="flex-start margb8" v-if="item.sdr_invite_model.startsWith('nkcor')">
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_storeCorDesc),25)}}</div>
						</div>
						<div class="flex-start margb8" v-else>
							<div class="req-contmsg">{{$util.mySubStr($util.filterHtml(item.sdr_contents),50)}}</div>
						</div>
					</template>
					<template v-else>
						<div class="flex-start margb8" v-if="item.sdr_invite_corName.length > 0">
							<div class="reqNail mindel-h">{{$util.mySubStr(item.sdr_invite_corName,25)}}</div>
						</div>
						<div class="reqNail margb8" v-if="item.sdr_invite_model == '1'">{{$util.mySubStr($util.filterHtml(item.sdr_invite_workNail),25)}}</div>
						<div class="flex-start margb8">
							<div class="nail-price" v-if="item.sdr_salary_offerType == '0'">薪资面议</div>
							<div class="flex-center-baseline nail-price" v-if="item.sdr_salary_offerType == '1'">
								<div class="currency_type">{{item.currency_symbol}}</div>
								<div class="flex-start ">
									<div class="amt margr4">{{item.salary_startRange}}</div>
									<div class="margr4">~</div>
									<div class="amt">{{item.salary_endRange}}</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
			<template v-if="!['26','27','28','29'].includes(item.sdr_contact_tagIndex)">
				<div v-if="item.sdr_isShowConcact == '1' && $util.isNotEmpty(item.sduc_whatsAppNum)" class="whatsapp_posi" @click.stop="whatsAppLink(item.sduc_whatsAppAc,item.sduc_whatsAppNum)">
					<div class="whatsapp_warp">
						<div class="icon_whatsapp"></div>
						<div class="icon_msg"><a>WhatsApp</a></div>
					</div>
				</div>
			</template>
			<div class="">
				<div class="flex-between">
					<div class="flex-start avatar">
						<div class="imgwarp">
							<img :src="showAvatarUrl(item.sdu_avatarUrl)" />
						</div>
						<div class="nick">{{item.sdu_nickName}}</div>
					</div>
					<div class="flex-start bot-right">
						<div class="icon-see margr4"></div>
						<div>{{item.browseNum}}</div>
					</div>
				</div>
				<div></div>
			</div>
			<!-- <div class="req-contmsg">{{item.sdr_contents}}</div> -->
		</div>
	</div>

</template>

<script>
	export default {
		name: 'reqInfo',
		props: {
			item: {
				type: Object,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				defalutImage: require('../assets/icon/noimg.jpg')
			}
		},
		created() {
			// console.log(this.item);
		},
		methods: {
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						console.log(this.baseUrlpath)
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			whatsAppLink(sduc_whatsAppAc,sduc_whatsAppNum){
				window.open('https://wa.me/' + this.$util.formateWhatsApp(sduc_whatsAppAc,sduc_whatsAppNum), '_blank');
			},
			goToDetail(itemInfo) {
				this.$router.push({
					name: 'reqDetail',
					query: {
						reqNo: itemInfo.sdr_reqId
					}
				});
			},
			play(fileId){
				const videoElements = document.getElementsByTagName('video');
				console.log('页面的video数量====>'+videoElements.length)
				if (videoElements && videoElements.length > 0) {
					for (let i = 0; i < videoElements.length; i++) {
						if (fileId === videoElements[i].id) {
							videoElements[i].play()
						} else {
							videoElements[i].pause()
						}
					}
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	/* .comp-req-warp:hover {
		transform:scale(1.05);
		transition:transform 0.45s ease;
	} */
	.whatsapp_posi {
		position: absolute;
		top: 0.125rem;
		right: 0.125rem;
		height: 1.5rem;
		line-height: 1.5rem;
		background-color: #43c353;
		border-radius: 6px;
		font-size: 0.875rem;
		overflow: hidden;
		.whatsapp_warp {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.icon_whatsapp {
				background: url('../assets/icon/whatsapp2.png') no-repeat center;
				background-size: 110%;
				width: 1.5rem;
				height: 1.5rem;
			}
			.icon_msg {
				a {
					margin-right: 4px;
					color: #FFF;
				}
				
			}
		}
	}
	
	.stick_top_warp {
		position: relative;
		display: inline-block;
		vertical-align: top;
		.icon_stick_top {
			background: url(../assets/icon/top.png) no-repeat center;
			background-size: 90%;
			width: 40px;
			height: 20px;
			background-position-y: 0px;
			background-position-x: 0px;
		}
	}
	.addr {
		position: absolute;
		bottom: 0;
		color: #fff;
		background-color: rgba(37, 35, 35, 0.5803921569);
		padding: 2px 6px 2px 2px;
		border-radius: 4px;
		font-size: 10px;

		.icon {
			background: url(../assets/icon/addr-empty.png) no-repeat center;
			background-size: 100%;
			width: 16px;
			height: 16px;
			margin-right: 0px;
		}
		.txt {
			text-align: left;
			margin-left: 0.125rem;
		}
	}

	.comp-req-warp {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		position: relative;
		cursor: pointer;
		.avatar .nick {
			font-size: 14px;
			margin-left: 4px;
		}

		.avatar .imgwarp {
			border-radius: 20px;
			overflow: hidden;
			width: 24px;
			height: 24px;
		}

		.avatar .imgwarp img {
			width: 100%;
			height: 100%;
		}

		.req-cont {
			padding: 6px 6px 10px 6px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.req-cont-body {
				margin-top: 4px;
				font-size: 14px;
				min-height: 88px;
				.item_mb {
					word-break: break-all;
					font-weight: 700;
				}
				.tag_warp {
					white-space: nowrap;
					width: 100%;
				}
				.rib-nail {
					font-size: 14px;
					height: 20px;
					line-height: 1.43;
					word-break: break-all;
				}
				.carry_from_place {
					font-size: 14px;
					margin-right: 6px;
				}

				.carry_to_place {
					font-size: 14px;
					margin-left: 6px;
				}

				.travelDate {
					margin-left: 6px;
					color: #969393;
					font-weight: 700;
				}
			}
		}

		.reqNail {
			word-break: break-all;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-align: left;
			font-size: 14px;
		}

		.req-cont .req-tag {
			border: 1px solid #bfbfbf;
			color: #777474;
			padding: 2px 6px;
			border-radius: 6px;
			font-size: 10px;
			margin-right: 4px;
			margin-top: 2px;
			letter-spacing: 0px;
		}

		.req-cont .sfc-place-from {
			clear: both;
			color: #4b4a4a;
			font-size: 14px;
			margin-right: 6px;
		}
		
		.req-cont .sfc-place-to {
			clear: both;
			color: #4b4a4a;
			font-size: 14px;
			margin-left: 6px;
		}
		
		.req-cont .req-cont-head {
			text-align: left;
			margin: 4px 0px 6px 0px;
		}
		
		.req-cont .req-tit {
			text-overflow: ellipsis;
			font-size: 14px;
			color: #333;
			font-weight: 600;
		}

		.req-contmsg {
			word-break: break-word;
			text-align: left;
		}

		.atta-img {
			width: 100%;
			height: 170px;
			overflow: hidden;
			position: relative;

			.el-image {
				height: 100%;
				width: 100%;
			}
			video {
				width: 100%;
				height: 100%;
			}
		}

		.bot-right {
			font-size: 12px;
		}

		.icon-sp {
			background: url(../assets/icon/start_where.png) no-repeat center;
			background-size: 100%;
			width: 20px;
			height: 20px;
		}

		.icon-direct {
			background: url(../assets/icon/to-right2.png) no-repeat center;
			background-size: 100%;
			width: 30px;
			height: 20px;
		}

		.icon-ep {
			background: url(../assets/icon/end_where.png) no-repeat center;
			background-size: 100%;
			width: 20px;
			height: 20px;
		}

		.icon-see {
			background: url(../assets/icon/see.png) no-repeat center;
			background-size: 100%;
			width: 18px;
			height: 18px;
		}

		.icon_fly {
			background: url(../assets/icon/fly.png) no-repeat center;
			background-size: 100%;
			width: 18px;
			height: 18px;
		}

		.icon_carto {
			background: url(../assets/icon/carto.png) no-repeat center;
			background-size: 100%;
			width: 18px;
			height: 18px;
		}
		
		.icon_ticket {
			background: url(../assets/icon/tocket.png) no-repeat center;
			background-size: 100%;
			width: 18px;
			height: 18px;
		}

		.nail-price {
			color: red;
			font-size: 14px;
			font-weight: bold;
		}

		.nail-price .op {
			font-size: 10px;
			color: #a09696;
			margin-left: 10px;
			text-decoration: line-through;
		}

		.nail-price .currency_type {
			font-size: 14px;
			font-weight: 600;
			margin-right: 6px;
		}

		.nail-price .amt {
			font-size: 14px;
			font-weight: 600;
		}

		.nail-price .amt-unit {
			font-weight: 700;
			font-size: 12px;
			display: flex;
			align-items: baseline;
			justify-content: center;
		}

		.nail-price .amt-unit .unit-split {
			margin: 0 2px;
		}
	}
</style>
