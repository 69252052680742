<template>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		created(){
			this.getPersonReqs()
		},
		mounted() {
		  
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
