
import Http from '@/utils/http.js'


export function queryUrl(url,param) {
  return Http.get(url,param)
}

export function queryUrlWithUInfo(url,param) {
  return Http.getWithUInfo(url,param)
}

export function postWithUInfo(url,param) {
  return Http.postWithUInfo(url,param)
}

export function doPost(url,param) {
  return Http.post(url,param)
}

export function commitFileUrl(url,param) {
  return Http.commitFile(url,param)
}